import React, {useEffect, useRef} from "react";
import {
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE,
    PRIMARY_COLOR_SECOND_ALTERNATIVE,
    SECONDARY_COLOR
} from "../../global/costants";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";
import MainButton from "../commons/mainButton";
import ItemBox from "../item/itemBox";
import {Calendar, Views} from "react-big-calendar";
import {formatsCalendar, localizer} from "../premium/myPremiumCalendar";
import Loader from "../commons/loader";
import ButtonsToOrderItems from "../premium/buttonsToOrderItems";
import ItemMinimalBox from "../item/itemMinimalBox";
import _ from 'lodash';
import {calculateTotal} from "../../global/totalFunctions";

function BookingPopupCreator({
                                 rentUser,
                                 orderDates,
                                 rangeSelected,
                                 handleSelectRange,
                                 clientName,
                                 setClientName,
                                 clientEmail,
                                 setClientEmail,
                                 notes,
                                 setNotes,
                                 slotSelected,
                                 popupCustomTimeSlotFrom,
                                 popupCustomTimeSlotTo,
                                 handleChangeCustomSlotTimeFrom,
                                 handleChangeCustomSlotTimeTo,
                                 handleSelectSlot,
                                 total,
                                 handleUpdateItemTotal,
                                 itemsToAdd,
                                 setItemsToAdd,
                                 canCreateNewBooking,
                                 setIsOpen,
                                 itemsToSelectFrom,
                                 setNewBookingItems,
                                 loadingAvailableItems,
                                 handleInsertOrUpdateNewBooking,
                                 theUserIsUpdatingABooking,
                                 handleResetBookingPopupCreatorState
                             }) {

    const newBookingRef = useRef()

    const thisIsASingleDayRent = !!!orderDates.to
    const rentSlotConfiguration = rentUser.rentSlotConfiguration

    const containerItemsAddedRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (newBookingRef.current && !newBookingRef.current.contains(event.target)) {
                handleResetBookingPopupCreatorState()
                // Il clic è avvenuto fuori dalla div della chat
                setIsOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    useEffect(() => {
        containerItemsAddedRef.current.scrollTo({
            top: containerItemsAddedRef.current.scrollHeight,
            behavior: 'smooth'
        })
    }, [itemsToAdd])

    function itemsFoundInThoseAdded(item) {
        return itemsToAdd.some(value => value.id === item.id)
    }

    function handlePressOnItem(itemToAddOrDelete) {
        if (!itemsFoundInThoseAdded(itemToAddOrDelete)) {
            const totalForThisItem = calculateTotal(itemToAddOrDelete, slotSelected, orderDates)
            itemsToAdd.push({...itemToAddOrDelete, total: totalForThisItem})
        } else {
            itemsToAdd.splice(_.indexOf(itemsToAdd, _.find(itemsToAdd, itemToAddOrDelete)), 1)
        }
        setItemsToAdd([...itemsToAdd])
    }

    function getOrderDates() {
        if (orderDates.to) {
            return <MyNormalText text={'Da ' + orderDates?.from + ' a ' + orderDates?.to + ' compresi'}/>
        }
        return <MyNormalText text={'Per il giorno ' + orderDates?.from}/>
    }

    const notesAreaStyle = {
        ...globalElementInputStyle.customInput, ...{
            width: '100%',
            height: '80px',
            resize: 'vertical'
        }
    }

    function getItemToAddStyle() {
        return {...pageStyle.itemsAvailableContainerOnY, ...{width: window.innerWidth * 0.6}}
    }

    function ifNotSingleDayRentDisabled() {
        return thisIsASingleDayRent ? undefined : 'gray'
    }

    function handleInsertClientName(name) {
        if (name?.length > 25) {
            return
        }
        setClientName(name)
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            <div ref={newBookingRef}
                 style={pageStyle.container}>
                <div style={pageStyle.newBooking}>
                    <div style={pageStyle.newBookingSection}>
                        <MyBoldText text={'Crea nuovo ordine'}
                                    fontSize={20}/>
                    </div>
                    <div style={pageStyle.newBookingSection}>
                        <MyBoldText text={'Cliente'}/>
                        <div style={pageStyle.marginBottom10}>
                            <MyNormalText text={'Nome (massimo 20 caratteri)'}/>
                            <input style={globalElementInputStyle.customInput}
                                   type="text"
                                   value={clientName}
                                   placeholder={'Mario Rossi'}
                                   onChange={(e) => handleInsertClientName(e.target.value)}/>
                        </div>
                        <MyNormalText text={'Email (non obbligatorio)'}/>
                        <input style={globalElementInputStyle.customInput}
                               type="text"
                               value={clientEmail}
                               placeholder={'mario.rossi@email.it'}
                               onChange={(e) => setClientEmail(e.target.value)}/>
                    </div>
                    <div style={pageStyle.newBookingSection}>
                        <MyBoldText text={'Date'}/>
                        <div style={pageStyle.marginBottom10}>
                            {getOrderDates()}
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <Calendar selectable={true}
                                      defaultDate={rangeSelected[0]?.start}
                                      localizer={localizer}
                                      formats={formatsCalendar}
                                      events={rangeSelected}
                                      views={[Views.MONTH]}
                                      style={{height: 450}}
                                      onSelectSlot={handleSelectRange}/>
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <div style={pageStyle.innerSectionSlots}>
                                <MyNormalText text={'Seleziona lo slot:'}
                                              color={ifNotSingleDayRentDisabled()}/>
                                <div style={{display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap'}}>
                                    {rentSlotConfiguration.map((slot, index) => {
                                        return (
                                            <div key={index.toString()}
                                                 style={pageStyle.slotContainer}>
                                                <MyNormalText
                                                    text={slot.from + ' - ' + slot.to + ' (' + slot.description + ')'}
                                                    color={ifNotSingleDayRentDisabled()}/>
                                                <input type="checkbox"
                                                       disabled={!thisIsASingleDayRent}
                                                       style={{width: 20, height: 20}}
                                                       checked={slot.type === slotSelected?.type}
                                                       onChange={() => handleSelectSlot(slot)}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={pageStyle.innerSectionSlots}>
                                <MyNormalText text={'Oppure seleziona l\'orario:'}
                                              color={ifNotSingleDayRentDisabled()}/>
                                <div style={pageStyle.clockPickerContainer}>
                                    <div style={pageStyle.containerHoursAndMinutesInput}>
                                        <div style={{marginRight: 5}}>
                                            <MyNormalText text={'Dalle'}
                                                          color={ifNotSingleDayRentDisabled()}/>
                                        </div>
                                        <input id="hoursInputFrom"
                                               type="time"
                                               value={popupCustomTimeSlotFrom}
                                               style={pageStyle.inputHourAndMinutes}
                                               disabled={!thisIsASingleDayRent}
                                               onChange={(e) => handleChangeCustomSlotTimeFrom(e.target.value)}/>
                                    </div>
                                </div>
                                <div style={{...pageStyle.clockPickerContainer, ...{marginTop: 10}}}>
                                    <div style={pageStyle.containerHoursAndMinutesInput}>
                                        <div style={{marginRight: 5}}>
                                            <MyNormalText text={'Alle'}
                                                          color={ifNotSingleDayRentDisabled()}/>
                                        </div>
                                        <input id="hoursInputTo"
                                               type="time"
                                               value={popupCustomTimeSlotTo}
                                               style={pageStyle.inputHourAndMinutes}
                                               disabled={!thisIsASingleDayRent}
                                               onChange={(e) => handleChangeCustomSlotTimeTo(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={pageStyle.newBookingSection}>
                        <div style={pageStyle.marginBottom10}>
                            <MyBoldText text={'Articoli'}/>
                        </div>
                        <MyNormalText text={'Qui appariranno gli articoli da te aggiunti.'}/>
                        <MyNormalText text={'Vai nel riquadro sotto per selezionarli.'}/>
                        <div ref={containerItemsAddedRef}
                             style={pageStyle.itemsAddedContainer}>
                            {itemsToAdd.map(item => {
                                return (
                                    <div key={item.id}
                                         style={pageStyle.itemContainer}>
                                        <ItemMinimalBox rentUser={rentUser}
                                                        item={item}
                                                        handleUpdateTotal={handleUpdateItemTotal}
                                                        showTotal={true}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={pageStyle.newBookingSection}>
                        <div style={{marginTop: 20, marginBottom: 5}}>
                            <ButtonsToOrderItems items={itemsToSelectFrom}
                                                 setItems={setNewBookingItems}
                                                 isAssignedRentCodeAsc={true}
                                                 buttonsColor={PRIMARY_COLOR}/>
                        </div>
                        <div style={getItemToAddStyle()}>
                            {loadingAvailableItems ?
                                <div style={{position: 'absolute'}}>
                                    <Loader color={'black'}/>
                                </div> :
                                <></>}
                            {itemsToSelectFrom.length === 0 && thisIsASingleDayRent ?
                                <MyNormalText text={'Seleziona uno slot giornaliero o una fascia oraria.'}/> :
                                itemsToSelectFrom.map(item => {
                                    const itemFound = itemsFoundInThoseAdded(item)
                                    return (
                                        <div key={item.id}
                                             style={pageStyle.itemContainer}>
                                            <div style={{flex: 1}}>
                                                <ItemBox rentUser={rentUser}
                                                         item={item}
                                                         hidePrices={true}
                                                         openOperations={false}/>
                                            </div>
                                            <MainButton text={itemFound ? 'Rimuovi' : 'Aggiungi'}
                                                        height={30}
                                                        action={() => handlePressOnItem(item)}
                                                        backgroundColor={itemFound ? HIGHLIGHT_COLOR : SECONDARY_COLOR}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={pageStyle.newBookingSection}>
                        <MyBoldText text={'Totale'}/>
                        <MyNormalText text={EURO_VALUE + total}/>
                    </div>
                    <div style={pageStyle.newBookingSection}>
                        <MyBoldText text={'Note'}/>
                        <textarea style={notesAreaStyle}
                                  value={notes}
                                  placeholder={'Numero di telefono, orario di arrivo...'}
                                  onChange={(e) => setNotes(e.target.value)}/>
                    </div>
                    <MainButton text={theUserIsUpdatingABooking ? 'Aggiorna' : 'Inserisci'}
                                fontSize={20}
                                action={() => handleInsertOrUpdateNewBooking()}
                                height={80}
                                disabled={!canCreateNewBooking}
                                backgroundColor={SECONDARY_COLOR}/>
                </div>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'fixed',
        left: '50%',
        top: '5%',
        bottom: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        zIndex: 1
    },
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)'
    },
    newBooking: {
        marginRight: 20,
        marginBottom: 20
    },
    newBookingSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        padding: 10,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    marginBottom10: {
        marginBottom: 10
    },
    innerSectionSlots: {
        padding: 10,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    clockPickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    containerButtonsOrderItems: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row'
    },
    slotContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        backgroundColor: PRIMARY_COLOR,
        marginTop: 10,
        marginRight: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    itemsAvailableContainerOnY: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxHeight: 600,
        minHeight: 20,
        width: 630,
        overflowY: 'auto'
    },
    itemsAddedContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 205,
        minWidth: 480,
        overflowY: 'auto'
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginTop: 10,
        marginRight: 10
    },
    containerHoursAndMinutesInput: {
        display: 'flex',
        alignItems: 'center'
    },
    inputHourAndMinutes: {
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        padding: 4,
        fontSize: 18,
        color: "#333",
        outline: "none",
        boxSizing: "border-box",
    }
}

export default BookingPopupCreator
