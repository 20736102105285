import React, {useEffect, useRef, useState} from "react";
import {
    FIRST_LEVEL_COLOR_OVER_WHITE,
    GLOBAL_BORDER_RADIUS,
    GLOBAL_SHADOW,
    PRIMARY_COLOR,
    SECONDARY_COLOR
} from "../../global/costants";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {db, functions} from "../../firebase";
import MyNormalText from "../commons/myNormalText";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import {httpsCallable} from "firebase/functions";
import MyBoldText from "../commons/myBoldText";
import BookingDetail from "../booking/bookingDetail";

const NEW_MESSAGE_MEX_LENGTH = 300

function ChatDetail({rentUser, chat, isActive, handleCloseChat}) {

    const chatBody = chat.body
    const [messages, setMessages] = useState([])
    let newMessageInitialValue = '';
    const [newMessage, setNewMessage] = useState(newMessageInitialValue)

    const [bookingIdSelected, setBookingIdSelected] = useState(null)
    const [openBookingDetail, setOpenBookingDetail] = useState(false)

    const messagesEndRef = useRef(null)

    useEffect(() => {
        const unsubscribeToChats = subscribeToNewChatMessages();
        setTimeout(() => {
            scrollToBottom()
        }, 500)
        return () => unsubscribeToChats();
    }, [isActive])

    if (!isActive) {
        return null;
    }

    function subscribeToNewChatMessages() {
        const q = query(
            collection(db, "rent_app_my_chats", rentUser.rentId, "chats", chat.chatId, "messages"),
            orderBy('timeStampInsertDate', 'asc'))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = []
            querySnapshot.forEach((doc) => messages.push(doc.data()))
            setMessages(messages)
        })
        return () => unsubscribe()
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
    }

    const handleSendMessage = (event) => {
        if (event.key === 'Enter') {
            if (newMessage === newMessageInitialValue || newMessage.length > NEW_MESSAGE_MEX_LENGTH) {
                return;
            }

            event.preventDefault();
            setNewMessage((prevValue) => prevValue + '\n');

            const createRentAppChatMessage = httpsCallable(functions, 'createRentAppChatMessage');
            let newMessageRequest = {
                chatId: chat.chatId,
                sender: rentUser.rentId,
                recipient: chatBody.client.uid,
                message: newMessage
            };
            setNewMessage(newMessageInitialValue);
            createRentAppChatMessage(newMessageRequest).catch(error => {
                if (error.message === 'too many messages') {
                    alert('Hai raggiunto il limite massimo di messaggi per questa chat.\nContatta il negozio direttamente.')
                }
            })
        }
    }

    function handleClickOnBooking(chat) {
        setBookingIdSelected(chat.body.bookingId)
        setOpenBookingDetail(true)
    }


    const handleChange = (event) => {
        setNewMessage(event.target.value)
    };

    const textAreaStyle = {
        ...globalElementInputStyle.customInput, ...{
            resize: 'none',
            height: 100
        }
    };

    return (
        <div style={pageStyle.container}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div onClick={() => handleClickOnBooking(chat)}
                     style={pageStyle.bookingLink}>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <MyNormalText
                                text={'Ordine ' + (chatBody.orderDates.to ? ' da ' : ' il ') + chatBody.orderDates.from}/>
                            <MyNormalText text={chatBody.orderDates.to ? ', fino a ' + chatBody.orderDates.to : ''}/>
                        </div>
                    </div>
                    <img src={require("../../imgs/actions/link.png")}
                         alt="link"
                         style={{width: 25, height: 25, marginLeft: 10}}/>
                </div>
                <BookingDetail rentUser={rentUser}
                               bookingId={bookingIdSelected}
                               isOpen={openBookingDetail}
                               setIsOpen={setOpenBookingDetail}/>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <img src={require("../../imgs/actions/minus.png")}
                         alt="minus"
                         onClick={() => handleCloseChat(chat)}
                         style={pageStyle.actionOnChatStyle}/>
                </div>
            </div>
            <div style={pageStyle.messagesContainer}>
                {messages.map(message => {
                    const myMessageStyle = {
                        padding: 10,
                        marginBottom: 10,
                        marginRight: 10,
                        marginLeft: 100,
                        backgroundColor: PRIMARY_COLOR,
                        alignSelf: 'flex-end',
                        borderRadius: GLOBAL_BORDER_RADIUS
                    }

                    const clientMessageStyle = {
                        padding: 10,
                        marginBottom: 10,
                        marginRight: 100,
                        backgroundColor: 'white',
                        alignSelf: 'flex-start',
                        borderRadius: GLOBAL_BORDER_RADIUS
                    }
                    return (
                        <div ref={messagesEndRef}
                             key={message.messageId}
                             style={message.sender === rentUser.rentId ? myMessageStyle : clientMessageStyle}>
                            <MyNormalText text={message.message}/>
                        </div>
                    )
                })}
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 5}}>
                <div style={pageStyle.languageContainer}>
                    <img src={require("../../imgs/actions/user_colored.png")}
                         alt="chat"
                         style={{width: 15, height: 15, marginRight: 5}}/>
                    <MyNormalText text={chat.language}
                                  color={'white'}/>
                </div>
                <MyBoldText text={newMessage.length + '/' + NEW_MESSAGE_MEX_LENGTH}
                            color={newMessage.length > NEW_MESSAGE_MEX_LENGTH ? 'red' : undefined}/>
            </div>
            <div>
                <textarea style={textAreaStyle}
                          value={newMessage}
                          placeholder={'Scrivi un messaggio...'}
                          onKeyDown={handleSendMessage}
                          onChange={handleChange}
                          rows={newMessage.split('\n').length}/>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: FIRST_LEVEL_COLOR_OVER_WHITE,
        padding: 10,
        bottom: 65,
        borderRadius: GLOBAL_BORDER_RADIUS,
        height: 600,
        width: 500,
        boxShadow: GLOBAL_SHADOW
    },
    messagesContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginTop: 10,
        marginBottom: 10,
        overflowY: 'auto'
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        cursor: 'pointer'
    },
    bookingLink: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: SECONDARY_COLOR,
        boxShadow: GLOBAL_SHADOW
    },
    languageContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'black',
        borderRadius: 50,
        padding: 5
    }
}

export default ChatDetail
