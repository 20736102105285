import {SIZES} from "../components/dashboard/myItems";
import {ASC, SIZE_OBJECT_VALUE} from "../components/premium/buttonsToOrderItems";

export function orderItemsBy(items, valueToOrder, order) {
    if (valueToOrder === SIZE_OBJECT_VALUE) {
        return items.sort((a, b) => {
            const sizeA = SIZES[a.size]
            const sizeB = SIZES[b.size]
            if (order === ASC) {
                return sizeA - sizeB
            } else {
                return sizeB - sizeA
            }
        })
    }

    return items.sort((a, b) => {
        const valueA = a[valueToOrder] ? a[valueToOrder] : ''
        const valueB = b[valueToOrder] ? b[valueToOrder] : ''
        if (order === ASC) {
            if (valueA < valueB) {
                return -1
            }
            if (valueA > valueB) {
                return 1
            }
        } else {
            if (valueA > valueB) {
                return -1
            }
            if (valueA < valueB) {
                return 1
            }
        }
    })
}
