import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import MyMenu from "../menu/myMenu";
import Footer from "../home/footer";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";
import Languages from "../home/languages";

function Contacts({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <Languages languageState={languageState}/>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText textWithTranslation={'contacts.page.title'}
                                language={languageState.language}
                                fontSize={30}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'Get in touch'}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.support.text'}
                                  language={languageState.language}/>
                    <br/>
                    <br/>
                    <MyBoldText textWithTranslation={'contacts.page.support.title'}
                                language={languageState.language}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.do.you.need.help'}
                                  language={languageState.language}/>

                    <br/>
                    <MyNormalText text={'help@locemsolutions.com'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'Work with us'}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.work.with.us'}
                                  language={languageState.language}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.work.with.us.text'}
                                  language={languageState.language}/>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

export default Contacts;
