import React from "react";
import {ENGLISH_LANGUAGE, GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../../App";

const traduction = require('../commons/traduction.json')

function MyNormalText({
                          text,
                          textWithTranslation,
                          language,
                          link,
                          hideUnderlainLink,
                          color,
                          fontSize,
                          textShadow,
                          textOverflow
                      }) {

    let style = {
        fontSize: fontSize ? fontSize : 16,
        color: color ? color : undefined,
        textShadow: textShadow ? textShadow : undefined
    }

    if (textOverflow) {
        style = {...style, ...pageStyle.overflow}
    }

    if (hideUnderlainLink) {
        style = {...style, ...pageStyle.hideUnderlainLink}
    }

    if (textWithTranslation && language) {
        function searchForTranslation() {
            if (language === ITALIAN_LANGUAGE) {
                return traduction.italian[textWithTranslation]
            } else if (language === GERMAN_LANGUAGE) {
                return traduction.german[textWithTranslation]
            } else if (language === ENGLISH_LANGUAGE) {
                return traduction.english[textWithTranslation]
            }
        }

        if (link) {
            return <a href={link}
                      style={style}>{searchForTranslation()}</a>
        }

        return <span style={style}>{searchForTranslation()}</span>
    }

    if (link) {
        return <a href={link}
                  style={style}>{text}</a>
    }

    return <span style={style}>{text}</span>
}

const pageStyle = {
    overflow : {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    hideUnderlainLink: {
        textDecoration: 'none',
        color: 'black',
        cursor: 'pointer'
    }
}

export default MyNormalText
