import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import MyMenu from "../menu/myMenu";
import Footer from "../home/footer";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";

function Help({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText text={'Richiedi supporto'}
                                fontSize={22}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText
                        text={'Hai bisogno di aiuto? Contattaci direttamente alla e-mail help@locemsolutions.com per qualsiasi esigenza, risponderemo nel più breve tempo possibile.'}/>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

export default Help;
