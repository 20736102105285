import React from "react";
import {BeatLoader, FadeLoader} from "react-spinners";
import {SECONDARY_COLOR} from "../../global/costants";
import MyBoldText from "./myBoldText";

function Loader({color, absoluteContained, absoluteFullPage, absoluteFullPageText}) {

    if (absoluteFullPage) {
        return (
            <div style={pageStyle.containerFullPageOpacity}>
                <div style={{opacity: 1}}>
                    <FadeLoader cssOverride={pageStyle.loaderAbsoluteFullPage}
                                color={color ? color : SECONDARY_COLOR}
                                loading={true}
                                aria-label="Loading Spinner"
                                data-testid="loader"/>
                    {absoluteFullPageText ?
                        <div style={pageStyle.middlePage}>
                            <MyBoldText text={absoluteFullPageText}
                                        color={'white'}
                                        fontSize={26}/>
                        </div> :
                        <></>}
                </div>
            </div>
        )
    } else if (absoluteContained) {
        return (
            <div style={{position: 'relative', height: '100%'}}>
                <div style={pageStyle.containerAbsolute}>
                    <div style={{opacity: 1}}>
                        <FadeLoader cssOverride={pageStyle.loaderAbsoluteFullPage}
                                    color={color ? color : SECONDARY_COLOR}
                                    loading={true}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BeatLoader color={color ? color : SECONDARY_COLOR}
                    loading={true}
                    cssOverride={pageStyle.loader}
                    aria-label="Loading Spinner"
                    data-testid="loader"/>
    )

}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
    },
    containerAbsolute: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loaderAbsoluteFullPage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    middlePage: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        marginTop: 80
    }
}

export default Loader
