import React, {useState} from "react";
import MainButton from "../commons/mainButton";
import {ENGLISH_LANGUAGE, GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../../App";

function Languages({languageState}) {

    const [openSelection, setOpenSelection] = useState(false)

    function handleActionOnFlag(languageSelected) {
        if (!openSelection) {
            setOpenSelection(true)
            return
        }

        languageState.setLanguage(languageSelected)
        setOpenSelection(false)
    }

    const buttons = [
        {
            language: ITALIAN_LANGUAGE,
            button: <MainButton image={require("../../imgs/languages/italian.png")}
                                action={() => handleActionOnFlag(ITALIAN_LANGUAGE)}
                                backgroundColor={'transparent'}
                                hideShadow={true}/>
        },
        {
            language: GERMAN_LANGUAGE,
            button: <MainButton image={require("../../imgs/languages/germany.png")}
                                action={() => handleActionOnFlag(GERMAN_LANGUAGE)}
                                backgroundColor={'transparent'}
                                hideShadow={true}/>
        },
        {
            language: ENGLISH_LANGUAGE,
            button: <MainButton image={require("../../imgs/languages/uk.png")}
                                action={() => handleActionOnFlag(ENGLISH_LANGUAGE)}
                                backgroundColor={'transparent'}
                                hideShadow={true}/>
        }
    ]

    function flagByLanguage() {
        if (languageState.language === ITALIAN_LANGUAGE) {
            return buttons.find(button => button.language === ITALIAN_LANGUAGE).button
        } else if (languageState.language === GERMAN_LANGUAGE) {
            return buttons.find(button => button.language === GERMAN_LANGUAGE).button
        } else if (languageState.language === ENGLISH_LANGUAGE) {
            return buttons.find(button => button.language === ENGLISH_LANGUAGE).button
        }
    }

    if (openSelection) {
        return (
            <div style={pageStyle.container}>
                {buttons.map((button, index) => {
                    return (
                        <div key={index.toString()}>
                            {buttons[index].button}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={pageStyle.container}>
            {flagByLanguage()}
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        margin: 20,
        zIndex: 1
    }
}

export default Languages
