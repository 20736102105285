import React, {useEffect, useState} from "react";
import {getUrlCoverImg} from "../../global/firebaseStorageRepo";
import MainButton from "../commons/mainButton";
import {signInWithEmailAndPassword, signOut} from 'firebase/auth';
import {auth, functions} from "../../firebase";
import {
    GLOBAL_BORDER_RADIUS,
    PREMIUM_COLOR,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE,
    SECONDARY_COLOR,
    subscriptionType
} from "../../global/costants";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import {httpsCallable} from "firebase/functions";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";

function Profile({user, setUser, handleOnLogout}) {

    const refund = user.refund
    const rentInfo = user.rentInfo
    const opening = user.opening
    const isAccountPremium = user.subscriptionType === subscriptionType.PREMIUM

    const [coverImage, setCoverImage] = useState("")
    const [discounts, setDiscounts] = useState([])
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordValidated, setNewPasswordValidated] = useState(false)
    const [loadingCover, setLoadingCover] = useState(true)
    const [loadingUpdateUser, setLoadingUpdateUser] = useState(false)
    const [loadingChangePassword, setChangePassword] = useState(false)

    useEffect(() => {
        getUrlCoverImg(user.rentId, rentInfo.cover)
            .then(urlCoverImage => setCoverImage(urlCoverImage))
    }, [])

    function logout() {
        signOut(auth)
            .then(() => handleOnLogout())
    }

    useEffect(() => {
        const getRentAppDiscounts = httpsCallable(functions, 'getRentAppDiscounts');
        getRentAppDiscounts({rentId: user.rentId})
            .then(async (response) => {
                setDiscounts(response.data.discounts)
            }).catch((error) => {
            alert(error.message);
        });
    }, [])

    function handleOpenCloseRent() {
        setLoadingUpdateUser(true)
        const updateRentAppUser = httpsCallable(functions, 'updateRentAppUser')
        updateRentAppUser({rentId: user.rentId, isClosed: !user.isClosed})
            .then((response) => {
                setUser(response.data.rentUser)
                setLoadingUpdateUser(false)
            }).catch((error) => {
            alert(error)
            setLoadingUpdateUser(false)
        })
    }

    const handleChangePassword = () => {
        if (!newPasswordValidated) {
            alert("Attenzione! La password deve essere lunga almeno 6 caratteri.")
            return
        }

        setChangePassword(true)
        const changePassword = httpsCallable(functions, 'updateUser')
        changePassword({user: {uid: user.rentId, password: newPassword}})
            .then(async (result) => {
                setChangePassword(false)
                if (result.data.message === 'OK') {
                    signInWithEmailAndPassword(auth, user.email, newPassword)
                        .then(() => {
                            alert("Password cambiata con successo!")
                            setNewPassword("")
                            setChangePassword(false)
                        })
                        .catch(() => setChangePassword(false))
                }
            }).catch(() => setChangePassword(false))
    }

    const imageStyle = {
        ...pageStyle.coverImage,
        opacity: loadingCover ? 0 : 1,
        transition: "opacity 0.5s ease-in-out",
    }

    function handleInsertPassword(e) {
        validateAndSetPassword(e.target.value, setNewPassword, setNewPasswordValidated)
    }

    function validateAndSetPassword(password, setPassword, setPasswordValidated) {
        let reg = /^(?=.*[a-zA-Z]).{6,}$/
        if (reg.test(password) === false) {
            setPassword(password)
            setPasswordValidated(false)
            return false;
        } else {
            setPassword(password)
            setPasswordValidated(true)
        }
    }

    function getMinutes(minutes) {
        if (minutes === '0') {
            return '.00'
        }
        return '.' + minutes
    }

    return (
        <div style={pageStyle.container}>
            <img style={imageStyle}
                 src={coverImage}
                 onLoad={() => setLoadingCover(false)}
                 alt="Cover"/>
            {isAccountPremium ?
                <div style={{...pageStyle.containerInfo, backgroundColor: PREMIUM_COLOR}}>
                    <div style={pageStyle.subContainerInfo}>
                        <MyBoldText text={'Account Premium'}
                                    fontSize={24}/>
                    </div>
                </div> :
                <></>}
            <div style={pageStyle.containerInfo}>
                <div style={pageStyle.subContainerInfo}>
                    <MyBoldText text={'Noleggio'}
                                fontSize={24}/>
                </div>
                <MyNormalText text={user.name}/>
                <MyNormalText text={user.email}/>
                <MyNormalText text={rentInfo.city + ', ' + rentInfo.street}/>
            </div>
            {user.contacts ?
                <div style={pageStyle.containerInfo}>
                    <div style={pageStyle.subContainerInfo}>
                        <MyBoldText text={'Contatti'}
                                    fontSize={24}/>
                        {user.contacts.email ?
                            <MyNormalText text={'- email: ' + user.contacts.email}/> :
                            <></>}
                        {user.contacts.phoneNumber ?
                            <MyNormalText text={'- telefono: ' + user.contacts.phoneNumber}/> :
                            <></>}
                    </div>
                </div> :
                <></>}
            <div style={pageStyle.containerInfo}>
                <div style={pageStyle.subContainerInfo}>
                    <MyBoldText text={'Orari apertura'}
                                fontSize={24}/>
                </div>
                <div>
                    <MyNormalText text={'Lunedì: '}/>
                    {opening.monday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.monday.from.hour + getMinutes(opening.monday.from.minute) + ' - ' + opening.monday.to.hour + getMinutes(opening.monday.to.minute)}/>}
                </div>
                <div>
                    <MyNormalText text={'Martedì: '}/>
                    {opening.tuesday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.tuesday.from.hour + getMinutes(opening.tuesday.from.minute) + ' - ' + opening.tuesday.to.hour + getMinutes(opening.tuesday.to.minute)}/>}
                </div>
                <div>
                    <MyNormalText text={'Mercoledì: '}/>
                    {opening.wednesday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.wednesday.from.hour + getMinutes(opening.wednesday.from.minute) + ' - ' + opening.wednesday.to.hour + getMinutes(opening.wednesday.to.minute)}/>}
                </div>
                <div>
                    <MyNormalText text={'Giovedì: '}/>
                    {opening.thursday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.thursday.from.hour + getMinutes(opening.thursday.from.minute) + ' - ' + opening.thursday.to.hour + getMinutes(opening.thursday.to.minute)}/>}
                </div>
                <div>
                    <MyNormalText text={'Venerdì: '}/>
                    {opening.friday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.friday.from.hour + getMinutes(opening.friday.from.minute) + ' - ' + opening.friday.to.hour + getMinutes(opening.friday.to.minute)}/>}
                </div>
                <div>
                    <MyNormalText text={'Sabato: '}/>
                    {opening.saturday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.saturday.from.hour + getMinutes(opening.saturday.from.minute) + ' - ' + opening.saturday.to.hour + getMinutes(opening.saturday.to.minute)}/>}
                </div>
                <div>
                    <MyNormalText text={'Domenica: '}/>
                    {opening.sunday.closed ?
                        <MyNormalText text={'Chiuso: '}/> :
                        <MyNormalText
                            text={opening.sunday.from.hour + getMinutes(opening.sunday.from.minute) + ' - ' + opening.sunday.to.hour + getMinutes(opening.sunday.to.minute)}/>}
                </div>
            </div>
            <div style={pageStyle.containerInfo}>
                <div style={pageStyle.subContainerInfo}>
                    <MyBoldText text={'Info rimborso/annullo'}
                                fontSize={24}/>
                    <MyNormalText
                        text={'I clienti potranno richiedere un rimborso fino a ' + refund.withinDays + ' gg prima'}/>
                    <MyNormalText
                        text={'La tua attività trattiene il ' + refund.fee + '% del totale a fronte di una richiesta di annullo'}/>
                </div>
            </div>
            <div style={pageStyle.containerInfo}>
                <div style={pageStyle.subContainerInfo}>
                    <MyBoldText text={'Lista sconti'}
                                fontSize={24}/>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {discounts.length === 0 ?
                            <MyNormalText text={'Nessun codice sconto creato.'}/> :
                            discounts.map(discount => {
                                return (
                                    <div style={pageStyle.discountContainer}>
                                        <MyNormalText text={discount.code + ' (-' + discount.percentageToApply + '%)'}/>
                                        <MyNormalText text={discount.isAvailable ? 'Attivo' : 'Disattivo'}/>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
            <div style={pageStyle.containerInfo}>
                <div style={pageStyle.subContainerInfo}>
                    <MyBoldText text={'Negozio ' + (user.isClosed ? 'chiuso' : 'aperto')}
                                fontSize={24}/>
                    <MyBoldText text={'Ora il tuo negozio è ' + (user.isClosed ? 'chiuso.' : 'aperto.')}/>
                    <MyNormalText text={'Utilizza questa funzionalità per aprire o chiudere il tuo negozio.'}/>
                    <MyNormalText
                        text={'Quando chiuso i clienti potranno vedere i tuoi articoli in vetrina ma non gli sarà possibile inviarti ordini.'}/>
                </div>
                <MainButton text={user.isClosed ? 'Apri' : 'Chiudi'}
                            loading={loadingUpdateUser}
                            backgroundColor={SECONDARY_COLOR}
                            action={handleOpenCloseRent}/>
            </div>
            <div style={pageStyle.containerInfo}>
                <div style={pageStyle.subContainerInfo}>
                    <MyBoldText text={'Cambia password'}
                                fontSize={24}/>
                    <MyNormalText text={'Deve essere lunga almeno 6 caratteri'}/>
                </div>
                <div style={{marginBottom: 10}}>
                    <input style={globalElementInputStyle.customInput}
                           type="text"
                           value={newPassword}
                           placeholder={'Nuova password...'}
                           onChange={(e) => handleInsertPassword(e)}/>
                </div>
                <MainButton text={'Cambia password'}
                            loading={loadingChangePassword}
                            backgroundColor={SECONDARY_COLOR}
                            action={handleChangePassword}/>
            </div>
            <div style={{marginTop: 20}}>
                <MainButton text={'Logout'}
                            textColor={'white'}
                            backgroundColor={'black'}
                            action={logout}/>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    containerInfo: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10,
        padding: 10
    },
    subContainerInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 5
    },
    coverImage: {
        maxWidth: '100%',
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10,
    },
    discountContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        marginRight: 5,
        marginBottom: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default Profile
