import React, {useState} from "react";
import {SECONDARY_COLOR} from "../../global/costants";
import MainButton from "../commons/mainButton";
import {orderItemsBy} from "../../global/itemsFunctions";

export const ASC = 'ASC'
export const DESC = 'DESC'
export const NAME = 'NAME'
export const NAME_OBJECT_VALUE = 'name'
export const ASSIGNED_RENT_CODE = 'ASSIGNED RENT CODE'
export const ASSIGNED_RENT_CODE_OBJECT_VALUE = 'assignedRentCode'
export const SIZE = 'SIZE'
export const SIZE_OBJECT_VALUE = 'size'
export const PREMIUM_DATA_AMOUNT_PRODUCED = 'AMOUNT_PRODUCED'
export const PREMIUM_DATA_AMOUNT_PRODUCED_OBJECT_VALUE = 'premiumDataAmountProduced'

function ButtonsToOrderItems({items, setItems, buttonsColor, premiumDataButtons}) {

    const [buttonOrderSelected, setButtonOrderSelected] = useState('')
    const [orderByName, setOrderByName] = useState(ASC)
    const [orderByAssignedRentCode, setOrderByAssignedRentCode] = useState(ASC)
    const [orderByInsertDateDirection, setOrderByInsertDateDirection] = useState(ASC)
    const [orderByPremiumDataAmount, setOrderByPremiumDataAmount] = useState(ASC)

    function handleOrderBy(order, typeToOrder, objectValueToOrder, setOrderBy) {
        setButtonOrderSelected(typeToOrder)
        setOrderBy(order)
        if (order === 'ASC') {
            orderItemsBy(items, objectValueToOrder, ASC)
        } else if (order === 'DESC') {
            orderItemsBy(items, objectValueToOrder, DESC)
        }
        if (setItems) {
            setItems([...items])
        }
    }

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.button}>
                <MainButton text={'Nome articolo'}
                            height={30}
                            backgroundColor={buttonOrderSelected === NAME ? SECONDARY_COLOR : buttonsColor}
                            secondaryImage={orderByName === ASC ? require("../../imgs/home/arrow-up.png") : require("../../imgs/home/arrow-down.png")}
                            action={() => handleOrderBy(orderByName === ASC ? DESC : ASC, NAME, NAME_OBJECT_VALUE, setOrderByName)}/>

            </div>
            <div style={pageStyle.button}>
                <MainButton text={'Codice'}
                            height={30}
                            backgroundColor={buttonOrderSelected === ASSIGNED_RENT_CODE ? SECONDARY_COLOR : buttonsColor}
                            secondaryImage={orderByAssignedRentCode === ASC ? require("../../imgs/home/arrow-up.png") : require("../../imgs/home/arrow-down.png")}
                            action={() => handleOrderBy(orderByAssignedRentCode === ASC ? DESC : ASC, ASSIGNED_RENT_CODE, ASSIGNED_RENT_CODE_OBJECT_VALUE, setOrderByAssignedRentCode)}/>
            </div>
            <div style={pageStyle.button}>
                <MainButton text={'Taglia'}
                            height={30}
                            backgroundColor={buttonOrderSelected === SIZE ? SECONDARY_COLOR : buttonsColor}
                            secondaryImage={orderByInsertDateDirection === ASC ? require("../../imgs/home/arrow-up.png") : require("../../imgs/home/arrow-down.png")}
                            action={() => handleOrderBy(orderByInsertDateDirection === ASC ? DESC : ASC, SIZE, SIZE_OBJECT_VALUE, setOrderByInsertDateDirection)}/>
            </div>
            {premiumDataButtons ?
                <div style={pageStyle.button}>
                    <MainButton text={'Totale'}
                                height={30}
                                backgroundColor={buttonOrderSelected === PREMIUM_DATA_AMOUNT_PRODUCED ? SECONDARY_COLOR : buttonsColor}
                                secondaryImage={orderByPremiumDataAmount === ASC ? require("../../imgs/home/arrow-up.png") : require("../../imgs/home/arrow-down.png")}
                                action={() => handleOrderBy(orderByPremiumDataAmount === ASC ? DESC : ASC, PREMIUM_DATA_AMOUNT_PRODUCED, PREMIUM_DATA_AMOUNT_PRODUCED_OBJECT_VALUE, setOrderByPremiumDataAmount)}/>
                </div> :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    button: {
        marginRight: 10,
        marginBottom: 5
    }
}

export default ButtonsToOrderItems
