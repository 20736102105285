import React, {useEffect, useState} from "react";
import {getUrlImg} from "../../global/firebaseStorageRepo";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";
import {GLOBAL_BORDER_RADIUS, GLOBAL_SHADOW} from "../../global/costants";
import ItemDetail from "./itemDetail";

function ItemMinimalBox({rentUser, item, showTotal, handleUpdateTotal}) {

    const rentId = rentUser.rentId
    const itemId = item.id
    const imageName = item.cover
    const itemName = item.name
    const assignedRentCode = item.assignedRentCode ? item.assignedRentCode : '-'
    const size = item.size

    const [openItemDetail, setOpenItemDetail] = useState(false)

    const [urlCover, setUrlCover] = useState(null)
    const [loadingImage, setLoadingImage] = useState(true)

    useEffect(() => {
        if (!localStorage.getItem(itemName)) {
            getUrlImg(rentId, itemName, 'cover', imageName)
                .then(url => {
                    localStorage.setItem(itemName, url);
                    setUrlCover(url)
                })
        } else {
            const imageUrl = localStorage.getItem(itemName)
            setUrlCover(imageUrl)
        }
    }, [])

    function handleOpenItemDetail() {
        setOpenItemDetail(true)
    }

    const imageStyle = {
        ...pageStyle.image,
        opacity: loadingImage ? 0 : 1,
        transition: "opacity 0.5s ease-in-out",
    }

    function handleInsertTotal(value) {
        if (value) {
            handleUpdateTotal(item, parseInt(value))
        } else {
            handleUpdateTotal(item, undefined)
        }
    }

    return (
        <div key={itemId}
             style={pageStyle.container}>
            <div style={pageStyle.containerImage}>
                <img onClick={handleOpenItemDetail}
                     style={imageStyle}
                     onLoad={() => setLoadingImage(false)}
                     src={urlCover}
                     alt="JPG Image Cover"/>
            </div>
            <div style={pageStyle.containerInfo}>
                <MyBoldText text={itemName}
                            fontSize={14}
                            textOverflow={true}/>
                <MyNormalText text={assignedRentCode.toUpperCase()}
                              fontSize={14}/>
                <MyBoldText text={size}
                            fontSize={14}/>
                {showTotal ?
                    <input style={pageStyle.totalInput}
                           type="number"
                           min="1"
                           value={item?.total}
                           onChange={(e) => handleInsertTotal(e.target.value)}/> :
                    <></>}
            </div>
            {openItemDetail ?
                <ItemDetail rentUser={rentUser}
                            itemId={itemId}
                            setIsOpen={setOpenItemDetail}/>
                : <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    containerImage: {
        width: 100,
        height: 75
    },
    containerInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        maxWidth: 100
    },
    image: {
        cursor: 'pointer',
        borderRadius: GLOBAL_BORDER_RADIUS,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        boxShadow: GLOBAL_SHADOW
    },
    totalInput: {
        marginTop: 5,
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 5,
        fontSize: 16,
        color: "black",
        outline: "none",
        minWidth: "100%",
        boxSizing: "border-box",
    }
}

export default ItemMinimalBox
