import React from "react";
import {useNavigate} from "react-router-dom";

function HeaderDashboard({pointer, navigateTo}) {

    const navigate = useNavigate();

    function getLogoLocemSolutions() {
        return <img src={require("../../imgs/home/logo_scritta.png")}
                    alt="Logo LoceM"
                    style={{height: 100, width: 260}}/>
    }

    function handleClick() {
        navigate(navigateTo)
    }

    if (pointer) {
        return (
            <div style={pageStyle.header}>
                <div style={{cursor: 'pointer'}}
                     onClick={handleClick}>
                    {getLogoLocemSolutions()}
                </div>
            </div>
        )
    }

    return (
        <div style={pageStyle.header}>
            <div onClick={handleClick}>
                {getLogoLocemSolutions()}
            </div>
        </div>
    )
}

const pageStyle = {
    header: {
        top: 0,
        display: 'flex',
        height: '8rem',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    title: {
        fontSize: 70
    }
}

export default HeaderDashboard
