import React, {useState} from "react";
import BookingRow from "../booking/bookingRow";
import Select from "react-select";
import {globalElementSelectStyle} from "../../global/globalElementSelectStyle";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import Loader from "../commons/loader";

function BookingHistory({
                            user,
                            bookings,
                            setBookings,
                            monthYearStrings,
                            setMonthYearStringSelected,
                            monthYearStringSelected,
                            handleSelectChat
                        }) {

    const [loading, setLoading] = useState(false)

    function fetchBookings(value) {
        setMonthYearStringSelected(value.label)

        setLoading(true)
        const firstDay = value.value.from
        const lastDay = value.value.to
        const rentBookingsByDateView = httpsCallable(functions, 'rentBookingsByDateView');
        rentBookingsByDateView({rentId: user.rentId, dateFilter: {from: firstDay, to: lastDay}})
            .then((response) => {
                const bookings = response.data.bookings;
                setBookings(bookings)
                setLoading(false)
            }).catch(() => setLoading(false))
    }

    return (
        <div style={pageStyle.bookingsList}>
            {loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <Select styles={globalElementSelectStyle}
                    placeholder={monthYearStringSelected ? monthYearStringSelected : 'Scegli un mese...'}
                    onChange={(newValue) => fetchBookings(newValue)}
                    options={monthYearStrings}/>
            {bookings.length > 0 ?
                <div style={{marginTop: 20}}>
                    {bookings?.map((booking, index) => {
                        const lastBooking = index === bookings.length - 1
                        const marginBottom = lastBooking ? 0 : 20
                        return (
                            <div key={booking.bookingId}
                                 style={{marginBottom: marginBottom}}>
                                <BookingRow rentUser={user}
                                            booking={booking}
                                            handleSelectChat={handleSelectChat}/>
                            </div>
                        )
                    })}
                </div> :
                <></>}
        </div>
    )
}

const pageStyle = {
    bookingsList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    }
}

export default BookingHistory
