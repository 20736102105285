import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import MyMenu from "../menu/myMenu";
import Footer from "../home/footer";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";

function LegalNotes({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText text={'NOTE LEGALI'}
                                fontSize={30}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'LOCEM SOLUTIONS SOCIETA’ A RESPONSABILITA’ LIMITATA SEMPLIFICATA'}
                                fontSize={22}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Via Roma, 56'}/>
                    <br/>
                    <MyNormalText text={'37063 – Isola della Scala (VR)'}/>
                    <br/>
                    <MyNormalText text={'ITALIA'}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Cod. Fiscale, partita iva e n. Iscrizione registro imprese: 04967430234'}/>
                    <br/>
                    <MyNormalText text={'Numero R.E.A.: VR – 460035'}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'PEC: locemsolutions@pro-pec.it'}/>
                    <br/>
                    <MyNormalText text={'Email: hello@locemsolutions.com'}/>
                    <br/>
                    <MyNormalText text={'Supporto tecnico: help@locemsolutions.com'}/>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

export default LegalNotes;
