import React, {useEffect, useRef} from "react";
import MainButton from "./mainButton";
import MyNormalText from "./myNormalText";
import {GLOBAL_BORDER_RADIUS} from "../../global/costants";

function CommonPopup({
                         message,
                         setIsOpen,
                         firstButtonText,
                         firstButtonColor,
                         firstButtonAction,
                         secondButtonText,
                         secondButtonColor,
                         secondButtonAction
                     }) {

    const commonPopupRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (commonPopupRef.current && !commonPopupRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                setIsOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            <div ref={commonPopupRef}
                 style={pageStyle.container}>
                <div style={pageStyle.containerPopup}>
                    <MyNormalText text={message}/>
                    <div style={{display: 'flex', marginTop: 10}}>
                        <MainButton text={firstButtonText}
                                    backgroundColor={firstButtonColor}
                                    action={firstButtonAction}
                                    height={35}/>
                        <div style={{margin: 5}}/>
                        <MainButton text={secondButtonText}
                                    backgroundColor={secondButtonColor}
                                    action={secondButtonAction}
                                    height={35}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        zIndex: 1
    },
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        overflowY: 'scroll'
    },
    containerPopup: {
        backgroundColor: 'white',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default CommonPopup
