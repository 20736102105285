import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import Footer from "../home/footer";
import MyMenu from "../menu/myMenu";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";
import Languages from "../home/languages";

function LocemBusinessApp({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <Languages languageState={languageState}/>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText text={'LoceM Business'}
                                fontSize={32}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.what.is.it'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.what.is.it.text'}
                                      language={languageState.language}/>
                    </div>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.for.adventures.lovers'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.for.adventures.lovers.text'}
                                      language={languageState.language}/>
                    </div>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.for.locel.business'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.for.locel.business.text'}
                                      language={languageState.language}/>
                    </div>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.always.present'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.if.you.want.to.collaborate.text'}
                                      language={languageState.language}/>
                    </div>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

const pageStyle = {
    sectionInfoAppArticle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        marginTop: 20,
        padding: 5
    },
    sectionTitle: {
        fontSize: 26,
        fontWeight: 'bold'
    }
}

export default LocemBusinessApp;
