import {MAX_DIV_WIDTH_HOME} from "./costants";

export const globalPageStyle = {
    pageBackground: {
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100vh',
        overflow: 'auto'
    },
    main: {
        flex: 1,
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20
    },
    mainSection: {
        textAlign: 'center',
        maxWidth: MAX_DIV_WIDTH_HOME,
        padding: 20
    },
    mainSectionLegal: {
        marginTop: 25,
        marginBottom: 50,
        alignSelf: 'center',
        maxWidth: MAX_DIV_WIDTH_HOME
    }
}
