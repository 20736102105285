import React, {useEffect, useRef} from "react";
import MainButton from "../commons/mainButton";
import {
    colorStatus,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    orderStatus,
    PRIMARY_COLOR,
    PRIMARY_COLOR_SECOND_ALTERNATIVE,
    SECONDARY_COLOR
} from "../../global/costants";
import Loader from "../commons/loader";
import {dashboardPageStyle} from "../dashboard/dashboardPageStyle";
import MyNormalText from "../commons/myNormalText";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import {formatUnixDate} from "../../global/functions";
import ItemBox from "../item/itemBox";
import MyBoldText from "../commons/myBoldText";

function AutomaticBookingDetail({
                                    rentUser,
                                    booking,
                                    acceptBooking,
                                    rejectBooking,
                                    loadingBooking,
                                    loadingAccept,
                                    loadingRejected,
                                    handleSelectChat,
                                    commentRejection,
                                    setCommentRejection,
                                    openRejectOptions,
                                    setOpenRejectOptions,
                                    setIsOpen
                                }) {

    const dateOrderTimeStamp = new Date(booking?.timeStampInsertDate?._seconds * 1000)
    const bookingDetailRef = useRef()

    const aDiscountWasApplied = booking.discount
    const isSingleDayRent = booking.isSingleDayRent
    const singleDaySlot = booking.orderDates?.singleDaySlot

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bookingDetailRef.current && !bookingDetailRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div dal booking detail
                setIsOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    function getStatus() {
        if (booking.status === orderStatus.PENDING) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.PENDING}}}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="total-refund"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Stato</span>
                        <MyNormalText text={'Da confermare'}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.ACCEPTED) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.ACCEPTED}}}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="total-refund"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Stato</span>
                        <MyNormalText text={'Accettato'}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.REJECTED) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.REJECTED}}}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="total-refund"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Stato</span>
                        <MyNormalText text={'Rifiutato'}/>
                        <MyNormalText text={'Motivazione: ' + booking.motivationRejection}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.REFUNDED) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.REFUNDED}}}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="total-refund"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Stato</span>
                        <MyNormalText text={'Rimborsato'}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.CANCELED_FROM_SYSTEM}}}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="total-refund"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Stato</span>
                        <MyNormalText text={'Cancellato (scadute 24h)'}/>
                    </div>
                </div>
            )
        }
    }

    function getOrderDates() {
        if (booking.orderDates.to) {
            return (
                <div style={pageStyle.bookingInfo}>
                    <img src={require("../../imgs/booking/calendar.png")}
                         alt="dates"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Date richieste</span>
                        <MyNormalText text={'da ' + booking.orderDates.from + ' a ' + booking.orderDates.to}/>
                    </div>
                </div>
            )
        }

        return (
            <div style={pageStyle.bookingInfo}>
                <img src={require("../../imgs/booking/calendar.png")}
                     alt="dates"
                     style={pageStyle.imageInfo}/>
                <div style={pageStyle.columnInfo}>
                    <span style={pageStyle.bookingInfoTitle}>Data richiesta</span>
                    <MyNormalText text={booking.orderDates.from}/>
                </div>
            </div>
        )
    }

    if (!loadingBooking && !booking.bookingId) {
        return (
            <div style={{
                ...pageStyle.container,
                backgroundColor: HIGHLIGHT_COLOR,
                padding: 20,
                borderRadius: GLOBAL_BORDER_RADIUS
            }}>
                <div style={dashboardPageStyle.dashboardMainDiv}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <MyNormalText text={'404 booking not found'}/>
                        <img src={require("../../imgs/actions/delete.png")}
                             alt="delete"
                             onClick={() => setIsOpen(false)}
                             style={pageStyle.actionOnChatStyle}/>
                    </div>
                </div>
            </div>
        )
    }

    const textAreaInputStyle = {
        ...globalElementInputStyle.customInput, ...{
            resize: 'none',
            height: 100
        }
    };

    function openChat() {
        setIsOpen(false)
        handleSelectChat(booking.chatId)
    }

    function createSlotDescription() {
        return singleDaySlot?.from + ' - ' + singleDaySlot?.to + ' (' + singleDaySlot?.description + ')'
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingBooking ?
                <Loader color={'white'}
                        absoluteFullPage={true}/> :
                <div ref={bookingDetailRef}
                     style={pageStyle.container}>
                    <div style={pageStyle.booking}>
                        <div style={pageStyle.bannerType}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 10}}>
                                    <MyBoldText text={'App LoceM'}/>
                                </div>
                                <img src={require("../../imgs/booking/logo.png")}
                                     alt="Status"
                                     style={pageStyle.imageInfo}/>
                            </div>
                        </div>
                        <div style={pageStyle.bookingInfoContainer}>
                            {getStatus()}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/id.png")}
                                     alt="id"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Codice ordine</span>
                                    <MyNormalText text={booking.orderId}/>
                                </div>
                            </div>
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/user.png")}
                                     alt="user"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Ordine di</span>
                                    <MyNormalText text={booking.client.name}/>
                                </div>
                            </div>
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/email.png")}
                                     alt="email"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>E-Mail</span>
                                    <MyNormalText text={booking.client.email}/>
                                </div>
                            </div>
                            {getOrderDates()}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/send.png")}
                                     alt="senttime"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Inviato il</span>
                                    <MyNormalText text={formatUnixDate(dateOrderTimeStamp)}/>
                                </div>
                            </div>
                            {isSingleDayRent ?
                                <div style={pageStyle.bookingInfo}>
                                    <img src={require("../../imgs/booking/single-day-rent.png")}
                                         alt="dates"
                                         style={pageStyle.imageInfo}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyBoldText text={'Fascia oraria'}/>
                                        <MyNormalText text={createSlotDescription()}/>
                                    </div>
                                </div> :
                                <></>}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/time.png")}
                                     alt="pickup"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Orario ritiro</span>
                                    <MyNormalText text={booking.arrivalTime.time}/>
                                </div>
                            </div>
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/language.png")}
                                     alt="language"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Lingua</span>
                                    <MyNormalText text={booking.language.toUpperCase()}/>
                                </div>
                            </div>
                            {aDiscountWasApplied ?
                                <div style={pageStyle.bookingInfo}>
                                    <img src={require("../../imgs/booking/discount.png")}
                                         alt="discount"
                                         style={pageStyle.imageInfo}/>
                                    <div style={pageStyle.columnInfo}>
                                        <span style={pageStyle.bookingInfoTitle}>Sconto applicato</span>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <MyNormalText text={booking.discount.codeApplied}/>
                                            <MyNormalText text={'(-' + booking.discount.percentageToApply + '%)'}/>
                                        </div>
                                    </div>
                                </div> :
                                <></>}
                            {aDiscountWasApplied ?
                                <div style={pageStyle.bookingInfo}>
                                    <img src={require("../../imgs/booking/discount.png")}
                                         alt="discount"
                                         style={pageStyle.imageInfo}/>
                                    <div style={pageStyle.columnInfo}>
                                        <span style={pageStyle.bookingInfoTitle}>Totale scontato</span>
                                        <MyNormalText text={'€ ' + booking.discount.totalDiscounted}/>
                                    </div>
                                </div> :
                                <></>}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/detail.png")}
                                     alt="total"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Totale</span>
                                    <MyNormalText text={'€ ' + booking.total}/>
                                </div>
                            </div>
                            {booking.status === orderStatus.REFUNDED ?
                                <div style={pageStyle.bookingInfo}>
                                    <img src={require("../../imgs/booking/refund.png")}
                                         alt="total-refund"
                                         style={pageStyle.imageInfo}/>
                                    <div style={pageStyle.columnInfo}>
                                        <span style={pageStyle.bookingInfoTitle}>Totale rimborsato</span>
                                        <MyNormalText text={'€ ' + booking.totalRefunded}/>
                                    </div>
                                </div> : <></>}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {booking.items?.map(item => {
                                return (
                                    <div key={item.item.id}
                                         style={{marginRight: 20, marginBottom: 20}}>
                                        <ItemBox rentUser={rentUser}
                                                 item={item.item}
                                                 hidePrices={true}
                                                 openOperations={false}/>
                                    </div>
                                )
                            })}
                        </div>
                        {booking.status === orderStatus.ACCEPTED && handleSelectChat ?
                            <MainButton secondaryImage={require('../../imgs/actions/chat.png')}
                                        action={() => openChat()}
                                        backgroundColor={SECONDARY_COLOR}/> :
                            <></>}
                        {booking.status === orderStatus.PENDING ?
                            <div style={pageStyle.actionButtonsContainer}>
                                <div style={pageStyle.buttonLeft}>
                                    <MainButton text={'Rifiuta'}
                                                action={() => setOpenRejectOptions(!openRejectOptions)}
                                                loading={loadingRejected}
                                                disabled={loadingRejected || loadingAccept}
                                                backgroundColor={colorStatus.REJECTED}/>
                                </div>
                                <div style={pageStyle.buttonRight}>
                                    <MainButton text={'Accetta'}
                                                action={acceptBooking}
                                                loading={loadingAccept}
                                                disabled={loadingRejected || loadingAccept}
                                                backgroundColor={colorStatus.ACCEPTED}/>
                                </div>
                            </div> : <></>}
                        {openRejectOptions ?
                            <div style={pageStyle.rejectOptions}>
                                <div style={pageStyle.margin10}>
                                        <textarea style={textAreaInputStyle}
                                                  value={commentRejection}
                                                  placeholder={'Scrivi un messaggio...'}
                                                  onChange={(e) => setCommentRejection(e.target.value)}
                                                  rows={commentRejection.split('\n').length}/>
                                </div>
                                <MainButton text={'Invia'}
                                            action={rejectBooking}
                                            backgroundColor={SECONDARY_COLOR}/>
                            </div> :
                            <></>}
                    </div>
                </div>}
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        zIndex: 1
    },
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        overflowY: 'scroll'
    },
    booking: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_SECOND_ALTERNATIVE
    },
    bannerType: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    bookingInfoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    bookingInfo: {
        display: 'flex',
        backgroundColor: 'white',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginRight: 20,
        marginBottom: 20
    },
    rejectOptions: {
        display: 'flex',
        flexDirection: 'column'
    },
    margin10: {
        marginTop: 10
    },
    bookingInfoTitle: {
        fontWeight: 'bold',
        fontSize: 20
    },
    buttonLeft: {
        width: '100%',
        marginRight: 10
    },
    buttonRight: {
        width: '100%',
        marginLeft: 10
    },
    actionButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        marginLeft: 10,
        cursor: 'pointer'
    },
    imageInfo: {
        height: 26,
        width: 26
    },
    columnInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 5
    }
}

export default AutomaticBookingDetail
