import React from "react";
import BookingRow from "../booking/bookingRow";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import Loader from "../commons/loader";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";

function SearchBookings({
                            user,
                            historyBookings,
                            setHistoryBookings,
                            searchValue,
                            setSearchValue,
                            loading,
                            setLoading,
                            handleSelectChat
                        }) {

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true)
        const getRentSearchBooking = httpsCallable(functions, 'getRentSearchBooking');
        getRentSearchBooking({rentId: user.rentId, searchField: searchValue})
            .then((response) => {
                const bookings = response.data.bookings
                setHistoryBookings(bookings)
                setLoading(false)
            }).catch(() => setLoading(false))
    };

    return (
        <>
            {loading ? <Loader absoluteFullPage={true}/> : <></>}
            <form onSubmit={handleSubmit}>
                <input style={globalElementInputStyle.customInput}
                       type="text"
                       value={searchValue}
                       placeholder={'Codice ordine o email...'}
                       onChange={(e) => setSearchValue(e.target.value)}/>
            </form>
            {historyBookings.length > 0 ?
                <div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
                    {historyBookings?.map((booking, index) => {
                        const lastBooking = index === historyBookings.length - 1
                        const marginBottom = lastBooking ? 0 : 20
                        return (
                            <div key={booking.bookingId}
                                 style={{marginBottom: marginBottom}}>
                                <BookingRow rentUser={user}
                                            booking={booking}
                                            handleSelectChat={handleSelectChat}/>
                            </div>
                        )
                    })}
                </div> : <></>}
        </>
    )
}

export default SearchBookings
