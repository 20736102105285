import React from "react";
import {globalPageStyle} from "../../global/globalPageStyle";
import Header from "../header/header";
import {websitePages} from "../../global/costants";
import MyMenu from "../menu/myMenu";
import MyBoldText from "../commons/myBoldText";

function NotFound({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText text={'404'}
                                fontSize={32}/>
                </div>
            </main>
        </div>
    )
}

export default NotFound
