export const PRIMARY_COLOR = '#82E0AA'
export const PRIMARY_COLOR_COMPLEMENTARY = '#e082b8'
export const PREMIUM_COLOR = '#efca3c'
export const PRIMARY_COLOR_FIRST_ALTERNATIVE = '#9df5c0'
export const PRIMARY_COLOR_SECOND_ALTERNATIVE = '#b4f5cc'
export const PRIMARY_COLOR_THIRD_ALTERNATIVE = '#c9f5d5'
export const SECONDARY_COLOR = '#7bacff'
export const HIGHLIGHT_COLOR = '#FF8936'
export const FIRST_LEVEL_COLOR_OVER_WHITE = '#E5E7E9'
export const SECOND_LEVEL_COLOR_OVER_WHITE = '#d3d3d7'
export const THIRD_LEVEL_COLOR_OVER_WHITE = '#b4b1b1'
export const DISABLED = '#969494'
export const MAX_DIV_WIDTH_HOME = 1100
export const MAX_DIV_WIDTH_DASHBOARD = 1400
export const GLOBAL_SHADOW = "1.5px 1.5px 1.5px rgba(0, 0, 0, 0.6)"
export const GLOBAL_BORDER_RADIUS = 5
export const GLOBAL_BORDER_RADIUS_ACCENTUATED = 20

export const orderStatus = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    REFUNDED: 'REFUNDED',
    CANCELED_FROM_SYSTEM: 'CANCELED FROM SYSTEM'
}

export const colorStatus = {
    PENDING: '#ffe15a',
    ACCEPTED: '#98e098',
    REJECTED: '#fc8657',
    REFUNDED: '#94a5bd',
    CANCELED_FROM_SYSTEM: '#a47070'
}

export const colorStatusOverlay = {
    PENDING: '#fdecb4',
    ACCEPTED: '#caecca',
    REJECTED: '#fca889',
    REFUNDED: '#c7cfd7',
    CANCELED_FROM_SYSTEM: '#c99191'
}

export const websitePages = {
    HOME: '/',
    PRIVACY: '/app/locem/privacy',
    GET_IN_TOUCH: '/get-in-touch',
    LEGAL_TERMS: '/app/locem/legal-terms',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    BOOKING_DETAIL: '/booking'
}

export const singleRentType = {
    FIRST_HALF_MORNING: 'FIRST_HALF_MORNING',
    SECOND_HALF_MORNING: 'SECOND_HALF_MORNING',
    FIRST_HALF_EVENING: 'FIRST_HALF_EVENING',
    SECOND_HALF_EVENING: 'SECOND_HALF_EVENING',
    HALF_DAY_MORNING: 'HALF_DAY_MORNING',
    HALF_DAY_EVENING: 'HALF_DAY_EVENING',
    FULL_DAY: 'FULL_DAY'
}

export const operationOnRentItemType = {
    BLOCKED: 'BLOCKED',
    REPLACEMENT: 'REPLACEMENT',
    ARCHIVE: 'ARCHIVE',
    UPDATE_ASSIGNED_RENT_CODE: 'UPDATE_ASSIGNED_RENT_CODE'
}

export const bookingType = {
    SYSTEM_AUTOMATIC: 'SYSTEM AUTOMATIC',
    RENT_SELF: 'RENT SELF'
}

export const subscriptionType = {
    PREMIUM: 'PREMIUM',
    NORMAL: 'NORMAL'
}

export const EURO_VALUE = '€'
