import React from "react";
import {ENGLISH_LANGUAGE, GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../../App";
import traduction from "./traduction.json";

function MyBoldText({text, textWithTranslation, language, fontSize, textShadow, link, textOverflow, color}) {

    let style = {
        fontWeight: 'bold',
        fontSize: fontSize ? fontSize : 16,
        color: color ? color : undefined,
        textShadow: textShadow ? textShadow : undefined
    }

    if (textOverflow) {
        style = {...style, ...overflow}
    }

    if (textWithTranslation && language) {
        function searchForTranslation() {
            if (language === ITALIAN_LANGUAGE) {
                return traduction.italian[textWithTranslation]
            } else if (language === GERMAN_LANGUAGE) {
                return traduction.german[textWithTranslation]
            } else if (language === ENGLISH_LANGUAGE) {
                return traduction.english[textWithTranslation]
            }
        }

        if (link) {
            return <a href={link}
                      style={style}>{searchForTranslation()}</a>
        }

        return <span style={style}>{searchForTranslation()}</span>
    }

    if (link) {
        return <a href={link}
                  style={style}>{text}</a>
    }

    return <span style={style}>{text}</span>
}

const overflow = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

export default MyBoldText
