import React from "react";
import {
    MAX_DIV_WIDTH_HOME,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE,
    PRIMARY_COLOR_SECOND_ALTERNATIVE
} from "../../global/costants";
import {globalPageStyle} from "../../global/globalPageStyle";
import {FaCode, FaProjectDiagram} from "react-icons/fa";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";

function WhoWeAre({language}) {

    const FONT_SIZE_TITLE = 26
    const FONT_SIZE_TEXT = 18
    const IMAGE_SIZE = 55

    return (
        <>
            <main style={{...globalPageStyle.main, ...{backgroundColor: PRIMARY_COLOR}}}>
                <div style={pageStyle.section}>
                    <MyBoldText textWithTranslation={'the.locem.brand'}
                                language={language}
                                fontSize={FONT_SIZE_TITLE}/>
                    <MyNormalText textWithTranslation={'the.locem.brand.first.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                    <br/>
                    <MyNormalText textWithTranslation={'the.locem.brand.second.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                    <br/>
                    <MyNormalText textWithTranslation={'the.locem.brand.third.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                    <br/>
                    <MyNormalText textWithTranslation={'the.locem.brand.fourth.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
            </main>
            <main style={{...globalPageStyle.main, ...{backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE}}}>
                <div style={pageStyle.section}>
                    <div style={pageStyle.subSection}>
                        <MyBoldText textWithTranslation={'home.vision'}
                                    language={language}
                                    fontSize={FONT_SIZE_TITLE}/>
                        <MyNormalText textWithTranslation={'home.vision.first.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                    <div style={pageStyle.subSection}>
                        <MyBoldText textWithTranslation={'home.mission'}
                                    language={language}
                                    fontSize={FONT_SIZE_TITLE}/>
                        <MyNormalText textWithTranslation={'home.mission.first.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                    <div style={pageStyle.subSection}>
                        <MyBoldText textWithTranslation={'home.no.one.like.us'}
                                    language={language}
                                    fontSize={FONT_SIZE_TITLE}/>
                        <MyNormalText textWithTranslation={'home.no.one.like.us.first.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                        <br/>
                        <MyNormalText textWithTranslation={'home.no.one.like.us.second.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                        <br/>
                        <MyNormalText textWithTranslation={'home.no.one.like.us.third.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                </div>
            </main>
            <main style={{...globalPageStyle.main, ...{backgroundColor: PRIMARY_COLOR_SECOND_ALTERNATIVE}}}>
                <div style={pageStyle.section}>
                    <div style={{marginBottom: 20}}>
                        <MyBoldText textWithTranslation={'home.key.values'}
                                    language={language}
                                    fontSize={FONT_SIZE_TITLE}/>
                    </div>
                    <div style={pageStyle.subSection}>
                        <FaProjectDiagram size={IMAGE_SIZE}
                                          color={'black'}/>
                        <MyNormalText textWithTranslation={'home.key.values.first.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                    <div style={pageStyle.subSection}>
                        <FaCode size={IMAGE_SIZE}
                                color={'black'}/>
                        <MyNormalText textWithTranslation={'home.key.values.second.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                    <div style={pageStyle.subSection}>
                        <img src={require("../../imgs/home/agile.png")}
                             alt="Agile"
                             style={{width: IMAGE_SIZE}}/>
                        <MyNormalText textWithTranslation={'home.key.values.third.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                    <div style={pageStyle.subSection}>
                        <img src={require("../../imgs/home/discovery.png")}
                             alt="Discovery"
                             style={{width: IMAGE_SIZE}}/>
                        <MyNormalText textWithTranslation={'home.key.values.fourth.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                        <MyNormalText textWithTranslation={'home.key.values.fifth.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                </div>
            </main>
        </>
    );
}

const pageStyle = {
    section: {
        maxWidth: MAX_DIV_WIDTH_HOME,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    subSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 20
    }
}

export default WhoWeAre;
