import React from "react";
import {GLOBAL_BORDER_RADIUS, HIGHLIGHT_COLOR, PRIMARY_COLOR_FIRST_ALTERNATIVE} from "../../global/costants";
import MainButton from "../commons/mainButton";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment/moment";

moment.locale('it', {week: {dow: 1}})
export const localizer = momentLocalizer(moment);
export const formatsCalendar = {
    // Personalizza il formato dell'ora nella vista giornaliera
    timeGutterFormat: 'H:mm', // Formato per l'orario visualizzato sulla sinistra
    eventTimeRangeFormat: ({start, end}, culture, localizer) =>
        `${localizer.format(start, 'H:mm', culture)} - ${localizer.format(end, 'H:mm', culture)}`, // Formato per l'intervallo di tempo degli eventi
}

function MyPremiumCalendar({
                               zoomCalendar,
                               setZoomCalendar,
                               calendarEvents,
                               handleSelectEvent,
                               handleSelectRange,
                               handleNavigate
                           }) {

    const eventPropGetter = (event, start, end, isSelected) => {
        let style = {}
        style.backgroundColor = event.backgroundColor
        style.color = 'white'
        style.borderWidth = 0
        style.borderRadius = 15
        return {style}
    }

    if (zoomCalendar) {
        return (
            <div style={pageStyle.containerFullPageOpacity}>
                <div style={pageStyle.innerContainerFullPage}>
                    <div style={pageStyle.buttonExit}>
                        <MainButton text={'Chiudi'}
                                    action={() => setZoomCalendar(!zoomCalendar)}
                                    secondaryImage={require("../../imgs/actions/delete.png")}
                                    height={30}
                                    backgroundColor={HIGHLIGHT_COLOR}/>
                    </div>
                    <Calendar selectable={true}
                              localizer={localizer}
                              formats={formatsCalendar}
                              events={calendarEvents}
                              defaultView={Views.MONTH}
                              views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                              style={pageStyle.calendarStyleFullPage}
                              onSelectSlot={handleSelectRange}
                              onSelectEvent={handleSelectEvent}
                              eventPropGetter={eventPropGetter}
                              onNavigate={handleNavigate}/>
                </div>
            </div>
        )
    }

    return (
        <Calendar selectable={true}
                  localizer={localizer}
                  formats={formatsCalendar}
                  events={calendarEvents}
                  defaultView={Views.MONTH}
                  views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                  style={pageStyle.calendarStyle}
                  onSelectSlot={handleSelectRange}
                  onSelectEvent={handleSelectEvent}
                  eventPropGetter={eventPropGetter}
                  onNavigate={handleNavigate}/>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        display: 'flex'
    },
    innerContainerFullPage: {
        position: 'fixed',
        left: '50%',
        top: '2%',
        bottom: '2%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        zIndex: 1
    },
    buttonExit: {
        marginBottom: 10,
        position: 'absolute',
        right: 0
    },
    calendarStyle: {
        height: window.innerHeight * 1.6,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    },
    calendarStyleFullPage: {
        height: window.innerHeight * 1.8,
        width: window.innerWidth * 0.95,
        padding: 10,
        marginTop: 40,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    }
}

export default MyPremiumCalendar
