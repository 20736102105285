import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import MyMenu from "../menu/myMenu";
import Footer from "../home/footer";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";

function LocemBusinessTermsOfService({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'PIATTAFORMA "LOCEM"'}
                                fontSize={30}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'TERMINI D’USO PER L’UTENTE COMMERCIALE'}
                                fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Versione in vigore a partire da 16/08/2023'}
                                  fontSize={24}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'1. DESCRIZIONE'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'LoceM Solution s.r.l.s. (di seguito, il Fornitore) è una start-up innovativa che si occupa  dello sviluppo, della produzione e della commercializzazione di prodotti e servizi innovativi ad alto valore tecnologico, con particolare riferimento alla digitalizzazione e automazione di processi di prenotazione e noleggio di beni e servizi.'}/>
                    <br/>
                    <MyNormalText
                        text={'Nell’ambito della propria attività d’impresa il Fornitore ha ideato una piattaforma, denominata “LoceM” (di seguito la “Piattaforma”) per la gestione on-line di servizi relativi al noleggio (attualmente solo) B2C (Vetrina Digitale, prenotazione e pagamento, ecc.) di mezzi e attrezzature relativi ad attività del tempo libero, con lo scopo di valorizzare il patrimonio culturale e paesaggistico del territorio.'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma si pone, in particolare, quale marketplace digitale, rivolto sia alle imprese che erogano i servizi di noleggio (di seguito gli Utenti Commerciali), che ai soggetti privati (per ora consumatori, di seguito, gli Utenti Privati), con il proposito di agevolare la stipulazione a distanza del contratto di noleggio tra Utenti Commerciali/Utenti Privati e la gestione delle attività prodromiche alla stipulazione e funzionali all’esecuzione del contratto di noleggio medesimo.'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma è accessibile dagli Utenti Commerciali, mediante apposito dashboard sul presente portale web https://locemsolutions.com/ (di seguito il Sito Web) e mediante l’applicazione LoceM Business App, compatibile con sistemi Android e IOS. Per gli Utenti Commerciali la Piattaforma costituisce un duttile strumento di organizzazione della Vetrina Digitale e processamento degli ordini/pagamenti/rimborsi;'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma è accessibile dagli Utenti Privati mediante la LoceM App, compatibile con sistemi Android e IOS. Attraverso LoceM App gli Utenti Privati avranno la possibilità di visitare le vetrine digitali degli Utenti Commerciali iscritti alla Piattaforma, selezionare gli articoli di interesse, e procedere alle operazioni di trasmissione dell’ordine di noleggio e pagamento del corrispettivo, nonché alla gestione successiva dell’ordine (annullamento, rimborso, ecc.).'}/>
                    <br/>
                    <MyNormalText
                        text={'I rapporti tra Fornitore e Utente Commerciale sono regolati, oltre che dai presenti Termini d’Uso della Piattaforma, da apposito “Contratto di adesione alla Piattaforma” e dai relativi allegati (di seguito, il Contratto), che si intendono qui integralmente richiamati.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'2. DEFINIZIONI'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'● Fornitore: indica LoceM Solutions società a responsabilità limitata semplificata, con sede in Via Roma n. 56, 37053 – Isola della Scala (VR) – Italia, Cod. Fiscale, partita iva e n. Iscrizione registro imprese: 04967430234, Numero R.E.A.: VR – 46005.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Piattaforma:  indica il marketplace digitale LoceM, ideato e sviluppato dal Fornitore per la gestione on-line di servizi relativi al noleggio di mezzi e attrezzature relativi ad attività del tempo libero, con lo scopo di valorizzare il patrimonio culturale e paesaggistico del territorio.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Utente Commerciale: indica un’impresa di noleggio di mezzi e attrezzature affiliata alla Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Utente Privato: indica il soggetto consumatore che intende prendere a noleggio i mezzi e le attrezzature rese disponibili dagli Utenti Commerciali all’interno della Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Contratto: indica il contratto di adesione al marketplace stipulato tra Utente Commerciale e Fornitore.'}/>
                    <br/>
                    <MyNormalText text={'● Account Business: indica il profilo utente dell’Utente Commerciale, attivato mediante l’iscrizione alla Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Posta: indica il sistema di messaggistica integrato di cui è dotata la Piattaforma, volto a favorire la comunicazione tra Utente Commerciale e Utente Privato, in relazione ad un determinato ordine di noleggio confermato.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Codice del Consumo: indica il Decreto Legislativo 6 settembre 2005 n. 206.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Ordine di Noleggio: indica l’ordine di noleggio inoltrato dall’Utente Privato mediante l’applicazione LoceM App. L’Ordine di Noleggio costituisce una proposta contrattuale formulata dall’Utente Privato all’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Ordine di Noleggio: indica l’ordine di noleggio inoltrato dall’Utente Privato\n' +
                            'mediante l’applicazione LoceM App. L’Ordine di Noleggio costituisce una proposta\n' +
                            'contrattuale formulata dall’Utente Privato all’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Conferma d’Ordine: indica la conferma dell’Ordine di Noleggio da parte dell’Utente Commerciale. La Conferma equivale all’accettazione della proposta contrattuale.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Rifiuto Ordine: indica un rifiuto dell’Ordine di Noleggio da parte dell’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Payment Gateway: indica il sistema di pagamento on-line adottato dalla Piattaforma per la gestione dei pagamenti relativi agli Ordini di Noleggio confermati.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Sito Web: indica il sito internet di proprietà del Fornitore, raggiungibile all’indirizzo https://locemsolutions.com/'}/>
                    <br/>
                    <MyNormalText
                        text={'● LoceM App: indica l’applicazione, ideata e sviluppata da LoceM, per l’accesso alla Piattaforma e l’utilizzo dei relativi servizi da parte dell’Utente Privato.'}/>
                    <br/>
                    <MyNormalText
                        text={'● LoceM Business App: indica l’applicazione, ideata e sviluppata da LoceM, per l’accesso alla Piattaforma e l’utilizzo dei relativi servizi da parte dell’Utente Privato.'}/>
                    <br/>
                    <MyNormalText
                        text={'● Dashboard: indica il pannello di controllo presente sul Sito Web, tramite il quale l’Utente Commerciale può accedere alla Piattaforma e utilizzare i relativi servizi.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'3. REGOLE FUNZIONAMENTO PIATTAFORMA'}
                                fontSize={24}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.1 Iscrizione – Attivazione Account Business'}
                                fontSize={18}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'L’adesione alla Piattaforma da parte dell’Utente Commerciale comporterà l’attivazione di un Account Business, tramite il quale l’Utente Commerciale potrà fruire dei servizi digitali della Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’attivazione dell’Account Business avviene mediante iscrizione alla Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’iscrizione dell’Utente Commerciale è effettuata a cura del Fornitore, sulla base dei dati e delle informazioni indicate dall’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Al termine della procedura di attivazione l’Utente Commerciale riceverà una notifica all’indirizzo e-mail indicato dall’Utente Commerciale, con il riepilogo dei dati di inserimento, unitamente al nome utente prescelto e una password provvisoria      (Credenziali d’Accesso). Con la medesima comunicazione saranno saranno altresì fornite le indicazioni per il download della LoceM Business App.'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma sarà accessibile per l’Utente Commerciale, sia dal Sito Web, che attraverso l’applicazione LoceM Business App, mediante l’inserimento delle Credenziali d’Accesso.'}/>
                    <br/>
                    <MyNormalText
                        text={'A seguito del primo accesso alla Piattaforma l’Utente Commerciale sarà invitato a modificare la password provvisoria.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.2 Servizi erogati dalla Piattaforma'}/>
                    <br/>
                    <MyNormalText text={'Attraverso il proprio Account Business ciascun Utente Commerciale ha a disposizione:'}/>
                    <br/>
                    <MyNormalText text={'a) Uno spazio cloud per allestimento della Vetrina Digitale dell’Utente Commerciale;'}/>
                    <br/>
                    <MyNormalText text={'b) Un archivio per la ricezione degli ordini di noleggio che saranno inviati dagli Utenti Privati per il tramite della LoceM App;'}/>
                    <br/>
                    <MyNormalText text={'c) Un repository “Storico” con la cronologia degli ordini e delle relative conferme, rifiuti e cancellazioni d’ordine;'}/>
                    <br/>
                    <MyNormalText text={'d) Un servizio integrato per la notifica (e-mail e in-app) agli Utenti Commerciali (per conto degli Utenti Privati) o agli Utenti Privati (per conto degli Utenti Commerciali) di eventi correlati all’ordine di noleggio (trasmissione/ricezione ordine, trasmissione/ricezione accettazione ordine, ecc.);'}/>
                    <br/>
                    <MyNormalText text={'e) Un servizio integrato di messaggistica (“Posta”), attivabile a discrezione dell’Utente Commerciale, per favorire la comunicazione tra Utenti Commerciali e Utenti Privati in relazione ad uno specifico ordine di noleggio, una volta confermato;'}/>
                    <br/>
                    <MyNormalText text={'f) L’indicizzazione all’interno del network della Piattaforma, con attivazione di appositi tools di interazione con app di terze parti per la navigazione GPS verso i punti noleggio dell’Utente Commerciale.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.3 Disattivazione Account Business e Cancellazione.'}/>
                    <br/>
                    <MyNormalText
                        text={'Ferme restando le ipotesi di limitazione, sospensione e cessazione del servizio di cui al successivo punto 5, l’Account Business sarà definitivamente disattivato decorsi 90 giorni dalla data di scioglimento, per qualsiasi causa, del Contratto (i.e. decorrenza del Termine Finale, recesso unilaterale dell’Utente Commerciale, cessazione del servizio da parte del Fornitore, risoluzione per inadempimento, ecc.).'}/>
                    <br/>
                    <MyNormalText
                        text={'Decorso il suddetto termine, saranno disattivate le Credenziali d’Accesso alla Piattaforma e revocata la licenza d’uso della LoceM Business App di cui al successivo punto 4.2.'}/>
                    <br/>
                    <MyNormalText
                        text={'Una volta disattivato l’Account Business, i dati e le informazioni ivi archiviate saranno definitivamente eliminate, fatta eccezione per quei dati e quelle informazioni che il Fornitore è tenuto a conservare per legge.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.4 Vetrina Digitale'}/>
                    <br/>
                    <MyNormalText
                        text={'L’allestimento della Vetrina Digitale è effettuato dal Fornitore, sulla scorta delle informazioni e della documentazione fornita dall’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Le informazioni richieste all’Utente Commerciale sono, in particolare:'}/>
                    <br/>
                    <MyNormalText
                        text={'- Indicazione della tipologia di ogni prodotto offerto in noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'- Corrispettivo orario/giornaliero di ciascun prodotto;'}/>
                    <br/>
                    <MyNormalText
                        text={'- Richiesta di cauzione al momento del ritiro e, in caso affermativo, il riferimento alla disciplina delle condizioni generali applicate dall’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'- Indicazione termini per annullamento gratuito (in conformità con la normativa di recesso del Codice del Consumo);'}/>
                    <br/>
                    <MyNormalText
                        text={'- Penali per annullamento ordine oltre i termini;'}/>
                    <br/>
                    <MyNormalText
                        text={'- Ulteriori informazioni richieste ex artt. 49 d.lgs. 206/2005 (Codice del Consumo)'}/>
                    <br/>
                    <MyNormalText
                        text={'La documentazione richiesta all’Utente Commerciale consiste, in particolare, in:'}/>
                    <br/>
                    <MyNormalText
                        text={'- Immagine vetrina Utente Commerciale (formato .jpeg)'}/>
                    <br/>
                    <MyNormalText
                        text={'- Immagini rappresentative di ciascun prodotto offerto in noleggio (formato .jpeg)'}/>
                    <br/>
                    <MyNormalText
                        text={'- Condizioni generali di contratto applicate dall’Utente Commerciale (formato .pdf)'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Commerciale si assume ogni responsabilità in relazione alle informazioni e alla documentazione fornita al Fornitore per la pubblicazione nella Vetrina Digitale.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di informazioni e/o documentazione manifestamente mendace, illecita, contraria a ordine pubblico o buon costume, il Fornitore può legittimamente rifiutare il caricamento nella Vetrina Digitale.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di successivo riscontro, anche per mezzo di segnalazioni da parte di Utenti Privati, di informazioni e/o documentazione mendace, illecita, contraria a ordine pubblico o buon costume, trova applicazione il successivo punto 5 (limitazione, sospensione e cessazione servizi).'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.5 Contratto di noleggio.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il contratto di noleggio tra Utente Commerciale e Utente Privato viene stipulato a distanza mediante scambio di proposta (Ordine di Noleggio) e accettazione (Conferma Ordine).'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Fornitore non è parte del suddetto contratto di noleggio, né può ritenersi in alcun modo vincolato agli obblighi del contratto medesimo, se non entro i limiti degli impegni assunti con i presenti Termini d’Uso e con il Contratto.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Fornitore non è agente dell’Utente Commerciale, né è soggetto ad alcun vincolo di subordinazione o esclusiva, nei confronti dell’Utente Commerciale, né detiene alcun potere di contrattazione per conto dell’Utente Commerciale o, più in generale, di rappresentanza, con la sola eccezione del mandato di cui ai successivi artt. 3.9 e 3.10 in tema di gestione di pagamenti e rimborsi, e nei limiti delle disposizioni medesime.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.6 Ordine di Noleggio'}/>
                    <br/>
                    <MyNormalText
                        text={'Ogni prodotto presente sulla Vetrina Digitale è prenotabile da parte degli Utenti Privati attraverso la LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Ciascun Utente Privato ha la possibilità di selezionare il prodotto di proprio interesse, se risulta disponibile per le date di ritiro / riconsegna prescelte dagli Utenti Privati nell’apposita sezione presente nella LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Al termine della procedura d’ordine l’Utente Privato è indirizzato ad una pagina di riepilogo, nella quale sono indicati i dati essenziali della prenotazione prescelta, conformemente a quanto previsto all’art. 49 del Codice del Consumo, nonché il link alla pagina delle condizioni generali di contratto applicate dall’Utente Commerciale e dal Fornitore.'}/>
                    <br/>
                    <MyNormalText
                        text={'Tra i dati essenziali della prenotazione prescelta sono, in particolare, indicati:'}/>
                    <br/>
                    <MyNormalText
                        text={'- generalità e indirizzo e dell’Utente Commerciale'}/>
                    <br/>
                    <MyNormalText
                        text={'- tipologia del prodotto;'}/>
                    <br/>
                    <MyNormalText
                        text={'- prezzo complessivo;'}/>
                    <br/>
                    <MyNormalText
                        text={'- data ritiro / riconsegna;'}/>
                    <br/>
                    <MyNormalText
                        text={'- eventuale richiesta di cauzione al momento del ritiro;'}/>
                    <br/>
                    <MyNormalText
                        text={'- politica di annullamento ordini applicata (indicazione dei termini per annullamento gratuito; penali per annullamento ordine oltre i termini)'}/>
                    <br/>
                    <MyNormalText
                        text={'Completato il suddetto iter l’Utente Privato può inviare l’ordine “con obbligo di pagamento” mediante apposito pulsante all’interno della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’invio dell’ordine comporta l’attivazione del procedimento tecnico di gestione del      pagamento integrale del corrispettivo previsto per il noleggio, secondo le modalità di cui al successivo punto 3.9.'}/>
                    <br/>
                    <MyNormalText
                        text={'Una volta effettuato l’invio dell’Ordine di Noleggio, il sistema informatico della Piattaforma procede alla verifica della sussistenza della provvista, attraverso il payment gateway di cui al successivo punto 3.9, relativamente al metodo di pagamento prescelto dall’Utente Privato.'}/>
                    <br/>
                    <MyNormalText
                        text={'In difetto di provvista, l’ordine non sarà preso in carico dal sistema e l’Utente Privato sarà immediatamente avvisato della mancata transazione mediante messaggio pop-up all’interno della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di esito positivo del controllo di provvista, il sistema informatico della Piattaforma effettua le seguenti operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) invio comunicazione all’Utente Privato (a mezzo e-mail), con il riepilogo dell’ordine inviato e le condizioni generali applicate dall’Utente Commerciale;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) registrazione dell’Ordine di Noleggio nel repository “Ordini” della LoceM App ;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) invio avviso ricezione ordine all’Utente Commerciale (a mezzo e-mail)'}/>
                    <br/>
                    <MyNormalText
                        text={'d) registrazione dell’Ordine di Noleggio nell’apposito repository dell’Account Business'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.7 Conferma d’Ordine'}/>
                    <br/>
                    <MyNormalText text={'Gli Ordini di Noleggio possono essere confermati dall’Utente Commerciale entro 24 ore dalla notifica dell’avviso di ricezione ordine.'}/>
                    <br/>
                    <MyNormalText text={'Nell’ipotesi di ordini che prevedano l’inizio del noleggio per un periodo inferiore alle successive 24 ore, non è assicurata la conferma da parte dell’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText text={'Con riferimento a ciascun Ordine di Noleggio ricevuto l’Utente Commerciale può all’interno del repository selezionare, mediante appositi pulsanti, se confermare (Conferma d’Ordine) o rifiutare (Rifiuto Ordine) l’Ordine di Noleggio.'}/>
                    <br/>
                    <MyNormalText text={'In caso di Conferma d’Ordine il sistema informatico della Piattaforma effettua le seguenti operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText text={'a) invio comunicazione all’Utente Privato (a mezzo e-mail), con la Conferma d’Ordine;'}/>
                    <br/>
                    <MyNormalText text={'b) registrazione della Conferma d’Ordine nel repository “Ordini” della LoceM App e avviso mediante notifica in-app;'}/>
                    <br/>
                    <MyNormalText text={'c) invio comunicazione all’Utente Commerciale (a mezzo e-mail), con la Conferma d’Ordine;'}/>
                    <br/>
                    <MyNormalText text={'d) archiviazione dell’ordine confermato nel repository “Storico” dell’Account Business;'}/>
                    <br/>
                    <MyNormalText text={'e) indicazione del prodotto prenotato come “non disponibile” nella Vetrina Digitale per il periodo interessato dall’ordine confermato;'}/>
                    <br/>
                    <MyNormalText text={'f) pagamento integrale del corrispettivo, secondo le modalità previste al successivo punto 3.9.'}/>
                    <br/>
                    <MyNormalText text={'Nella Conferma d’Ordine sono indicate le seguenti informazioni:'}/>
                    <br/>
                    <MyNormalText text={'- avviso che l’Ordine di Noleggio è stato accettato;'}/>
                    <br/>
                    <MyNormalText text={'- generalità, indirizzo e contatti dell’Utente Commerciale'}/>
                    <br/>
                    <MyNormalText text={'- tipologia del prodotto;'}/>
                    <br/>
                    <MyNormalText text={'- prezzo complessivo;'}/>
                    <br/>
                    <MyNormalText text={'- data ritiro / riconsegna;'}/>
                    <br/>
                    <MyNormalText text={'- eventuale richiesta di cauzione al momento del ritiro;'}/>
                    <br/>
                    <MyNormalText text={'- politica di annullamento ordini applicata (indicazione dei termini per annullamento gratuito; penali per annullamento ordine oltre i termini);'}/>
                    <br/>
                    <MyNormalText text={'- eventuali ulteriori informazioni di cui all’art. 49 Codice del Consumo;'}/>
                    <br/>
                    <MyNormalText text={'- condizioni generali applicate dall’Utente Commerciale;'}/>
                    <br/>
                    <MyNormalText text={'Una volta eseguite le operazioni di cui alle precedenti lettere a) – d), il contratto di noleggio tra Utente Commerciale e Utente Privato deve intendersi perfezionato.'}/>
                    <br/>
                    <MyNormalText text={'In caso di rifiuto dell’Ordine di Noleggio, oppure di mancata accettazione entro il termine di 24 ore dalla ricezione dell’Ordine di Noleggio, l’Ordine di Noleggio deve intendersi privo di efficacia.'}/>
                    <br/>
                    <MyNormalText text={'In caso di Rifiuto Ordine il sistema informatico della Piattaforma effettua le seguenti operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText text={'a) invio dell’avviso Rifiuto Ordine all’Utente Privato (a mezzo e-mail);'}/>
                    <br/>
                    <MyNormalText text={'b) registrazione del Rifiuto Ordine all’interno del repository “Ordini” della LoceM App e avviso mediante notifica in-app;'}/>
                    <br/>
                    <MyNormalText text={'c) invio comunicazione all’Utente Commerciale (a mezzo e-mail), del Rifiuto Ordine;'}/>
                    <br/>
                    <MyNormalText text={'d) registrazione dell’ordine rifiutato nei termini nel repository “Storico” dell’Account Business.'}/>
                    <br/>
                    <MyNormalText text={'In caso di mancata conferma dell’Ordine di Noleggio da parte dell’Utente Commerciale, entro 24 ore dalla notifica di ricezione, all’Utente Commerciale sarà disabilitata la funzionalità di conferma dell’Ordine all’interno della LoceM Business App e del Sito Web.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.8 Annullamento ordine'}/>
                    <br/>
                    <MyNormalText
                        text={'Prima della Conferma d’Ordine, l’Utente Privato ha sempre la possibilità di annullare gratuitamente l’Ordine di Noleggio, selezionando l’apposito pulsante all’interno del repository “Ordini” della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Successivamente alla Conferma d’Ordine, l’annullamento dell’Ordine di Noleggio è possibile in conformità alla politica di annullamento applicata da ciascun Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'In base alla politica applicata dal singolo Utente Commerciale l’annullamento può essere gratuito, oppure prevedere il pagamento di una penale, anche in relazione alle tempistiche entro cui viene richiesto.'}/>
                    <br/>
                    <MyNormalText
                        text={'ATTENZIONE: ai sensi dell’art. 59, comma 1, lett. n) del Codice del Consumo Italiano, il diritto di recesso gratuito previsto dall’art. 52 del Codice del Consumo Italiano per i contratti stipulati a distanza dal consumatore NON è applicabile ai servizi riguardanti le attività del tempo libero, qualora il contratto preveda una data o un periodo di esecuzione specifici.'}/>
                    <br/>
                    <MyNormalText
                        text={'Considerata la tipologia di servizi offerti dalla Piattaforma, è dunque possibile che il contratto di noleggio stipulato dall’Utente Privato rientri nel regime di esenzione al diritto di recesso.'}/>
                    <br/>
                    <MyNormalText
                        text={'Ciascun Utente Commerciale applica la propria politica di annullamento degli ordini confermati, in conformità e nel pieno rispetto dei diritti riservati al consumatore dalla normativa del Codice del Consumo Italiano, anche in riferimento al diritto di recesso sopra indicato.'}/>
                    <br/>
                    <MyNormalText
                        text={'La politica di annullamento adottata da ciascun Utente Commerciale è indicata nella pagina di riepilogo dell’ordine e nelle comunicazioni di riepilogo dell’ordine e di Conferma d’Ordine.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Privato può procedere all’annullamento mediante l’apposito pulsante nel repository “Ordini” della LoceM App. '}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di annullamento da parte dell’Utente Privato, il sistema informatico della Piattaforma effettua le seguenti operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) invio della conferma dell’annullamento dell’ordine all’Utente Privato (a mezzo e-mail);'}/>
                    <br/>
                    <MyNormalText
                        text={'b) registrazione dell’annullamento dell’ordine all’interno del repository “Ordini” della LoceM App;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) invio comunicazione all’Utente Commerciale (a mezzo e-mail), dell’annullamento dell’ordine;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) registrazione dell’annullamento nel repository “Storico” dell’Account Business.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’annullamento dell’ordine comporta il rimborso del pagamento effettuato dall’Utente Privato, al netto dell’eventuale penale applicata dall’Utente Commerciale, secondo le modalità di cui al successivo punto 3.10 (Gestione dei Rimborsi).'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.9 Gestione dei pagamenti'}/>
                    <br/>
                    <MyNormalText
                        text={'Le operazioni di pagamento sono interamente gestite, in forza di mandato conferito dall’Utente Commerciale al Fornitore con il Contratto, mediante apposito tool all’interno della Piattaforma, integrato con il payment gateway di terze parti a ciò abilitate (attualmente Stripe.com, https://stripe.com/it-us/legal/consumer).'}/>
                    <br/>
                    <MyNormalText
                        text={'Il procedimento è così articolato:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) all’interno della procedura d’ordine di noleggio l’Utente Privato deve inserire i dati relativi al metodo di pagamento prescelto;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) al termine della procedura l’Utente Privato viene indirizzato alla pagina di riepilogo di cui al precedente punto 3.6, con la richiesta di procedere all’invio dell’ordine “con obbligo di pagamento”;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) una volta inviato l’ordine, il sistema informatico della Piattaforma provvederà a pre-autorizzare il pagamento, per il tramite del payment gateway;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) in caso di conferma dell’ordine da parte dell’Utente Commerciale, l’importo pre-autorizzato sarà effettivamente incassato dal Fornitore, per il tramite del payment gateway;'}/>
                    <br/>
                    <MyNormalText
                        text={'e) in caso di rifiuto dell’ordine da parte dell’Utente Commerciale, oppure di mancata conferma dell’ordine entro il termine di 24 ore dalla notifica di ricezione dell’ordine, l’importo pre-autorizzato sarà automaticamente svincolato e restituito all’Utente Privato nei termini previsti dal gestore del payment gateway.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.10 Gestione dei rimborsi'}/>
                    <br/>
                    <MyNormalText
                        text={'Le operazioni di rimborso sono interamente gestite, in forza di mandato conferito dall’Utente Commerciale al Fornitore con il Contratto, mediante apposito tool all’interno della Piattaforma, integrato con il payment gateway di terze parti a ciò abilitate (attualmente Stripe.com, https://stripe.com/it-us/legal/consumer).'}/>
                    <br/>
                    <MyNormalText
                        text={'In sintesi, il procedimento è così articolato:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) A seguito dell’annullamento di un ordine da parte dell’Utente Privato, la LoceM App rende disponibile mediante apposito tasto la richiesta di rimborso del prezzo versato (al netto dell’eventuale penale applicata in base alla politica di annullamento adottata dall’Utente Commerciale), se ne ricorrono i presupposti;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) Una volta trasmessa la richiesta di rimborso, il sistema informatico della Piattaforma invia al payment gateway l’ordine di rimborso sul metodo di pagamento prescelto dall’Utente Privato al momento dell’invio dell’Ordine di Pagamento. In tale ipotesi l’importo sarà effettivamente accreditato all’Utente Privato nei termini previsti dal gestore del payment gateway e dall’istituto bancario di appoggio dell’Utente Privato;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) Nel caso in cui il payment gateway rilevi errori nel procedimento di rimborso (ad es. scadenza della carta di credito utilizzata dall’Utente Privato per il pagamento del noleggio), l’Utente Privato riceverà una notifica (e-mail e in-app), con l’invito a contattare il supporto tecnico del Fornitore per l’aggiornamento del metodo di rimborso.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.11 Ritiro degli articoli noleggiati'}/>
                    <br/>
                    <MyNormalText
                        text={'Il ritiro degli articoli noleggiati dovrà essere effettuato a cura dell’Utente Privato presso il punto di noleggio prescelto, nel giorno e nell’ora indicati nell’Ordine di Noleggio, e previa esibizione all’Utente Commerciale della Conferma d’Ordine.'}/>
                    <br/>
                    <MyNormalText
                        text={'Eventuali modifiche dell’orario di ritiro dovranno essere concordate direttamente con l’Utente Commerciale, ai contatti indicati nella Conferma d’Ordine, prima dell’orario del ritiro indicato nell’Ordine di Noleggio.'}/>
                    <br/>
                    <MyNormalText
                        text={'In ogni caso, in assenza di comunicazioni da parte dell’Utente Privato, l’ordine dovrà ritenersi annullato se l’articolo non viene ritirato entro il tempo massimo di 60 minuti dall’orario indicato per il ritiro. In tal caso l’Utente Privato non avrà diritto al rimborso del prezzo pagato (penale pari al 100%).'}/>
                    <br/>
                    <MyNormalText
                        text={'L’articolo dovrà poi essere riconsegnato nel giorno e nell’ora indicati nell’Ordine di Noleggio e in conformità con le condizioni generali applicate dal singolo Utente Commerciale.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'3.12 Sistema di messaggistica integrato (“Posta”)'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma è dotata di un sistema di messaggistica integrato (Posta) per il tramite della quale l’Utente Privato ha la possibilità di interfacciarsi direttamente con l’Utente Commerciale (e viceversa) per eventuali comunicazioni inerenti agli Ordini di Noleggio confermati.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il servizio di Posta è attivabile a discrezione dell’Utente Commerciale e accessibile dall’Utente Commerciale da un’apposita sezione presente nel Sito Web.'}/>
                    <br/>
                    <MyNormalText
                        text={'In particolare il servizio di Posta è attivabile dall’Utente Commerciale successivamente alla conferma di un determinato ordine e rimarrà attivo sino alle ore 23.59 dell’ultimo giorno di noleggio. Il servizio di Posta si disattiva automaticamente a partire dalle ore 0.00 del primo giorno successivo al termine del noleggio e in caso di eventuale annullamento dell’ordine.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il servizio di Posta deve essere utilizzato al solo scopo di ricevere e fornire informazioni in merito agli Ordini di Noleggio confermati.'}/>
                    <br/>
                    <MyNormalText
                        text={'E’ pertanto vietata qualsiasi condotta volta a:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) indurre gli Utenti Privati ad annullare l’Ordine di Noleggio già confermato e/o effettuarne altri ordini mediante modalità esterne alla Piattaforma, anche verso la promessa di sconti o condizioni economiche più favorevoli;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) trasmettere o chiedere dati personali ulteriori rispetto a quanto normalmente richiesto dalla Piattaforma per la gestione degli Ordini di Noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) chiedere agli Utenti Privati o promettere agli Utenti Commerciali recensioni o commenti positivi, anche mediante canali esterni alla Piattaforma, anche verso la promessa o la richiesta di sconti o altre pratiche volte a falsare il giudizio dell’utente;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) utilizzare espressioni offensive o in qualunque modo discriminatore nei confronti degli Utenti Privati'}/>
                    <br/>
                    <MyNormalText
                        text={'e9 utilizzare il servizio di Posta per scopi estranei alla gestione dell’Ordine di Noleggio per il quale è stato attivato.'}/>
                    <br/>
                    <MyNormalText
                        text={'Al fine di garantire la sicurezza e il rispetto delle disposizioni normative e regolamentari vigenti, nonché l’osservanza dei Termini d’Uso della Piattaforma da parte degli Utenti Commerciali e degli Utenti Privati, il Fornitore si riserva la facoltà di effettuare controlli, diretti (su segnalazione dell’Utente o della Pubblica Autorità), ovvero a campione, sullo scambio di comunicazioni all’interno del servizio di Posta, nel pieno rispetto della normativa sui trattamento dei dati personali e degli obblighi di riservatezza contrattualmente assunti con l’Utente Commerciale.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'4. PROPRIETA’ INTELLETTUALE. LICENZA D’USO.'}
                                fontSize={24}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'4.1. Proprietà intellettuale.'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma, il Sito Web e il relativo dominio, nonché la LoceM Business App sono di esclusiva proprietà del Fornitore, unico soggetto che può legittimamente disporne e concederne a terzi di relativi Diritti di utilizzazione economica.'}/>
                    <br/>
                    <MyNormalText
                        text={'La documentazione, le immagini, i caratteri, la grafica e il layout, il software, nonché la presentazione e l’elaborazione degli stessi, il codice sorgente, gli altri codici e format scripts di implementazione, e, più in generale, tutte le altre componenti informatiche e altri contenuti della Piattaforma, del Sito Web e della LoceM Business App sono di proprietà del Fornitore, oppure sono stati a questo concessi in utilizzo dai rispettivi legittimi proprietari. '}/>
                    <br/>
                    <MyNormalText
                        text={'I contenuti di cui al precedente paragrafo sono tutelati e protetti dalle disposizioni vigenti in materia di proprietà intellettuale e industriale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Tutti i diritti in questione (compresi, senza limitazione, diritti d’autore, brevetti, know-how, marchi e segni distintivi) appartengono al Fornitore oppure sono stati a questo concessi in utilizzo da parte dei rispettivi e legittimi proprietari.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’accesso alla Piattaforma e/o la licenza d’uso della LoceM Business App non forniscono all’Utente Commerciale il diritto di cedere o sublicenziare a terzi le relative credenziali per l’utilizzo e/o la licenza d’uso, né di riprodurre, di modificare, di distribuire, di ripubblicare, in alcuna forma anche parziale i suddetti contenuti, senza l’espressa autorizzazione scritta da parte del titolare dei relativi diritti di sfruttamento, fatte salve le ipotesi tassative legittimamente riconosciute ai sensi degli artt. 64bis e ss. L.d.A..'}/>
                    <br/>
                    <MyNormalText
                        text={'La violazione di uno dei diritti di privativa spettanti al Fornitore o a questi concessi in utilizzo dai rispettivi legittimi titolari costituisce causa di responsabilità per l’Utente Commerciale.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'4.2. Licenza d’uso della LoceM Business App'}/>
                    <br/>
                    <MyNormalText
                        text={'La gestione dell’Account Business è consentita attraverso l’utilizzo della LoceM Business App, entro i limiti e nel pieno rispetto dei termini e delle condizioni di cui al precedente punto 4.1.'}/>
                    <br/>
                    <MyNormalText
                        text={'La LoceM Business App è un’applicazione software di esclusiva proprietà di LoceM, compatibile con qualsiasi device Android (versioni AndroidOS 11 e successive) e IOS (versione IOS 13.7 e successive).'}/>
                    <br/>
                    <MyNormalText
                        text={'La LoceM Business App è fornita all’Utente Commerciale in licenza d’uso, non esclusiva, secondo le condizioni e per tutto il periodo di vigenza del Contratto.'}/>
                    <br/>
                    <MyNormalText
                        text={'Per qualsiasi ipotesi di perdita di efficacia del Contratto di cui al precedente punto 3.3, l’utilizzo della LoceM Business App sarà consentito sino ai 90 giorni successivi alla data di scioglimento del Contratto, onde consentire l’evasione degli ordini pendenti.'}/>
                    <br/>
                    <MyNormalText
                        text={'Decorso il termine suddetto la licenza d’uso sarà revocata.'}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'4.3. Applicazioni e siti web di terze parti'}/>
                    <br/>
                    <MyNormalText
                        text={'All’interno della Piattaforma potrebbero essere presenti tool di interazione con applicazioni di terze parti, oppure link a siti web gestiti da soggetti diversi dal Fornitore.'}/>
                    <br/>
                    <MyNormalText
                        text={'I presenti Termini d’Uso non si applicano all’utilizzo delle applicazioni o siti web di terzi a cui si potrebbe accedere tramite la Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'L\'utilizzo di qualsiasi applicazione o sito web di terze parti potrebbe essere soggetto a termini e condizioni aggiuntivi.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Fornitore non opera alcun controllo in merito alle informazioni, ai prodotti, ai servizi eventualmente offerti o alle politiche privacy e cookie adottate da tali applicazioni o siti web di terze parti.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Commerciale è quindi invitato a prendere autonomamente visione delle policy e dei termini d’uso delle suddette applicazioni e siti web di terze parti.'}/>
                    <br/>
                    <MyNormalText
                        text={'In ogni caso a fronte di eventuali comunicazioni da parte degli Utenti Commerciali sul malfunzionamento o non affidabilità di tali applicazioni e siti web di terze parti, il Fornitore potrà, a proprio insindacabile giudizio, valutare di sospenderne il collegamento.'}/>
                    <br/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'5. LIMITAZIONE, SOSPENSIONE E CESSAZIONE SERVIZI (in conformità con art. 4 Reg. UE 1150/2019)'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'5.1 Il Fornitore si riserva la facoltà di sospendere o limitare la fornitura dei servizi della Piattaforma all’Utente Commerciale, a causa di determinate condotte da parte di quest’ultimo con riferimento alle modalità di utilizzo delle Piattaforma, ovvero dei prodotti/servizi offerti dall’Utente Commerciale, oppure in caso di provvedimenti legislativi, regolamentari od ordini della Pubblica Autorità.'}/>
                    <br/>
                    <MyNormalText
                        text={'5.2 Salvo che il fatto costituisca causa di risoluzione di diritto del Contratto, oppure in caso di adempimento di un obbligo normativo, regolamentare od ordine della Pubblica Autorità, il Fornitore si riserva, altresì, la facoltà di cessare completamente la fornitura dei servizi della Piattaforma all’Utente Commerciale, a causa di determinate condotte da parte di quest’ultimo con riferimento alle modalità di utilizzo delle Piattaforma, ovvero dei prodotti/servizi offerti dall’Utente Commerciale. La suddetta prerogativa riservata al Fornitore costituisce un diritto di recesso unilaterale dal Contratto in favore di quest’ultimo.'}/>
                    <br/>
                    <MyNormalText
                        text={'5.3 La decisione di adottare una delle misure di cui ai precedenti punti 5.1. e 5.2. sarà preceduta dalla notifica mediante PEC di un preavviso all’Utente Commerciale, almeno 30 giorni prima della data di sospensione/limitazione/cessazione. Nel preavviso saranno indicate dal Fornitore le motivazioni della decisione adottata, con l’invito a fornire eventuali controdeduzioni (all’indirizzo PEC  locemsolutions@pro-pec.it) entro e non oltre il termine di 15 giorni dalla notifica del preavviso. Nei 15 giorni successivi alla ricezione delle controdeduzioni sarà notificata all’Utente Commerciale la decisione sulle controdeduzioni. In caso di rigetto delle controdeduzioni sarà adottata la misura preannunciata nel preavviso.'}/>
                    <br/>
                    <MyNormalText
                        text={'5.4 Il termine di cui al precedente punto 5.3 non si applica nel caso in cui:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) Il Fornitore è tenuto ad adempiere a un obbligo normativo, regolamentare od ordine della Pubblica Autorità che gli impone di cessare la fornitura dell’insieme dei suoi servizi di intermediazione online a un determinato Utente Commerciale in un modo che non gli consente di rispettare il termine di preavviso, oppure'}/>
                    <br/>
                    <MyNormalText
                        text={'b) Il Fornitore esercita un diritto di recesso fondato su motivi imperativi a norma del diritto italiano in conformità con il diritto dell’Unione Europea;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) L’Utente Commerciale ha ripetutamente violato i presenti Termini d’Uso, oppure è incorso in una causa di risoluzione di diritto del Contratto. '}/>
                    <br/>
                    <MyNormalText
                        text={'5.5 Salva l’ipotesi in cui la causa di limitazione/sospensione/cessazione risieda in divieti di legge, regolamenti od ordini da parte della Pubblica Autorità di prosecuzione dell’attività imprenditoriale da parte dell’Utente Commerciale ovvero di commercializzazione di determinati beni o prodotti, gli eventuali ordini pendenti (già confermati), la cui esecuzione è successiva rispetto alla data di applicazione della limitazione/sospensione/cessazione, saranno comunque evasi dall’Utente Commerciale e visibili all’interno della Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'5.6 In caso di cessazione ai sensi del precedente punto 5.2. si applica il precedente punto 3.3 (Disattivazione Account. Cancellazione).'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'6. GARANZIE. MANUTENZIONE. AGGIORNAMENTO. SUPPORTO TECNICO.'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'6.1. Il Fornitore garantisce esclusivamente che la Piattaforma e la LoceM Business App sono in grado di eseguire le operazioni previste e indicate nelle descrizioni tecniche riportate nella Scheda Tecnica allegata al Contratto.'}/>
                    <br/>
                    <MyNormalText
                        text={'6.2 La garanzia di cui al precedente punto 6.1. è, peraltro, condizionata al corretto funzionamento dei devices e dei relativi software di sistema utilizzati dall’Utente Commerciale ed al corretto uso degli stessi da parte dell’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'6.3 Il Fornitore ha adottato e costantemente adotta tutte le misure e i presidi idonei al mantenimento in sicurezza della Piattaforma e degli Account dei propri iscritti, nei limiti dell’ordinaria diligenza.'}/>
                    <br/>
                    <MyNormalText
                        text={'6.4 Per qualsiasi segnalazione, richiesta di informazioni o supporto tecnico in relazione a condotte di Utenti Privati o altri Utenti Commerciali, modalità o problemi tecnici di funzionamento della Piattaforma, l’Utente Commerciale è invitato a inviare una richiesta di supporto secondo le seguenti modalità:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) tramite l’apposita sezione presente all’interno della Piattaforma (Sito Web e/o LoceM Business App), ovvero'}/>
                    <br/>
                    <MyNormalText
                        text={'b) inviando una e-mail all’indirizzo help@locemsolutions.com'}/>
                    <br/>
                    <MyNormalText
                        text={'c)  contattando il Fornitore agli eventuali ulteriori recapiti da questi indicati.'}/>
                    <br/>
                    <MyNormalText
                        text={'La richiesta di supporto sarà presa in carico entro 24 ore dalla ricezione da parte del Fornitore ed evasa nel più breve tempo possibile, anche in ragione della natura e dell’urgenza dell’intervento richiesto.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'7. ESONERO RESPONSABILITA’'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText text={'7.1. Il Fornitore declina ogni responsabilità in relazione a:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) adempimento delle obbligazioni assunte dall’Utente Commerciale nei confronti dell’Utente Privato in virtù del contratto di noleggio stipulato con quest’ultimo per il tramite della Piattaforma;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) sicurezza e conformità dei beni e delle attrezzature offerte in noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) contestazioni da parte della Pubblica Autorità in relazione alla messa a noleggio di beni e attrezzature non sicure o non conformi;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) danni o pregiudizi arrecati a Utenti Privati o a terzi dall’Utente Commerciale, e/o dai beni e dalle attrezzature da questi concessi in noleggio;'}/>
                    <br/>
                    <MyNormalText text={'e) inadempimenti contrattuali, condotte lesive o abusive, danni provocati all’Utente Commerciale o a terzi, da parte degli Utenti Privati, durante l’esecuzione del contratto di noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'f) malfunzionamenti dei tool di interazione con applicazioni di terze parti integrate con il sistema informatico della Piattaforma (es. navigazione GPS), ovvero danni o pregiudizi subiti dall’utilizzo di tool di interazione con applicazioni di terze parti o degli eventuali link a siti web di terze parti;'}/>
                    <br/>
                    <MyNormalText
                        text={'g) malfunzionamenti del payment gateway e tempistiche di rimborso applicate dagli istituti di credito sui quali poggiano i metodi di pagamento utilizzati dagli Utenti Privati.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'7.2. Il Fornitore declina ogni responsabilità in relazione alle informazioni e alla documentazione pubblicata dall’Utente Commerciale e/o fornita dall’Utente Commerciale al Fornitore per la pubblicazione nella Vetrina Digitale, e per qualsiasi ulteriore danno o pregiudizio derivante a terzi dalla pubblicazione delle suddette informazioni / documentazione.'}/>
                    <br/>
                    <MyNormalText
                        text={'7.3. Il Fornitore declina ogni responsabilità in ordine al contenuto, ai dati, alle informazioni e alle espressioni, nonché alle condotte fraudolente o scorrette in qualsiasi forma veicolate dall’Utente Commerciale verso l’Utente Privato, e viceversa, anche mediante il servizio di Posta.'}/>
                    <br/>
                    <MyNormalText
                        text={'7.4. Il Fornitore declina ogni responsabilità per qualsiasi danno o pregiudizio derivante dall’utilizzo della Piattaforma tramite il Sito Web o della LoceM Business App, non imputabile a dolo o colpa grave del Fornitore medesimo, a titolo esemplificativo e non esaustivo:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) incompatibilità della Locem Business App e/o del Sito Web con i devices utilizzati dall’Utente Commerciale;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) danni o malfunzionamenti ai devices/softwares utilizzati dall’Utente Commerciale per l’accesso alla Piattaforma;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) problemi tecnici o malfunzionamenti in genere, imprevisti o non prevedibili con l’ordinaria diligenza, della Locem Business App e/o del Sito Web;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) attacchi subiti dall’Utente durante l’utilizzo della Locem Business App e/o del Sito Web (a titolo esemplificativo virus, malware, attacchi hacker, ecc.)'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'8. REGOLE POSIZIONAMENTO'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'8.1. Ai sensi dell’art. 5 Reg. 1150/2019, il Fornitore dichiara che il posizionamento dei risultati delle ricerche all’interno della Piattaforma avviene secondo il seguente ordine di parametri:'}/>
                    <br/>
                    <MyNormalText text={'a) durante la fase iniziale, sino alla raccolta di almeno 10 adesioni alla Piattaforma: ordine temporale di adesione alla Piattaforma da parte degli Utenti Commerciali;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) una volta raggiunta la soglia minima di adesioni di cui alla precedente lettera a), il posizionamento all’interno della Piattaforma avverrà secondo i seguenti parametri'}/>
                    <br/>
                    <MyNormalText text={'- posizione geografica da cui accede l’Utente Privato;'}/>
                    <br/>
                    <MyNormalText
                        text={'- valutazione resa dall’Utente Privato al termine di un noleggio;'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'8.2 Il Fornitore si riserva la facoltà di inserire meccanismi volti ad influire sul posizionamento di cui al precedente punto 8.1, verso il pagamento di un corrispettivo da parte dell’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'8.3 Nell’ipotesi di cui al precedente punto 8.2, il punto di noleggio dell’Utente Commerciale sarà inserito, per il periodo di tempo contrattualmente stabilito, tra i primi risultati della lista. In questo caso, il risultato della ricerca sarà contrassegnato dalla dicitura “sponsorizzato”.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyBoldText text={'9 ACCESSO AI DATI'}
                                fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'9.1 Ai sensi dell’art. 9 Reg. 1150/2019, il Fornitore dichiara quanto di seguito.'}/>
                    <br/>
                    <MyNormalText
                        text={'9.2 Il Fornitore ha la possibilità di accedere ai dati (personali e non) forniti dagli Utenti Commerciali e dagli Utenti Privati nell’ambito di utilizzo della Piattaforma e, nello specifico, ha accesso a tutti i dati richiesti dalla Piattaforma per la corretta erogazione dei relativi servizi. Con riferimento ai dati presenti nel servizio di Posta, si rinvia all’informativa privacy, secondo la quale la verifica da parte del Fornitore è consentita al fine di verificare un uso illecito della Piattaforma e il corretto adempimento dei Termini d’Uso del Servizio.'}/>
                    <br/>
                    <MyNormalText
                        text={'9.3. L’Utente Commerciale ha accesso ai soli dati (personali e non) forniti dall’Utente Privato mediante l’invio dell’Ordine di Noleggio, ovvero mediante il servizio di Posta e, nello specifico ha accesso a tutti i dati richiesti dalla Piattaforma per il perfezionamento e la corretta esecuzione del contratto di noleggio.'}/>
                    <br/>
                    <MyNormalText
                        text={'9.4 L’Utente Commerciale ha la possibilità di accedere, previa richiesta al Fornitore, ai soli dati statistici in forma aggregata e anonima relativi all’andamento dei propri noleggi su base periodica (annuale).'}/>
                    <br/>
                    <MyNormalText
                        text={'9.5 Il Fornitore si riserva di fornire a terzi i soli dati di cui al precedente punto 9.4, sempre in forma aggregata ed anonima, in tal caso anche con riferimento alle generalità degli Utenti Commerciali da cui sono stati ricavati e sempre limitatamente alle sole finalità statistiche.'}/>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

export default LocemBusinessTermsOfService;
