import React from "react";
import {
    FIRST_LEVEL_COLOR_OVER_WHITE,
    GLOBAL_BORDER_RADIUS,
    GLOBAL_SHADOW,
    THIRD_LEVEL_COLOR_OVER_WHITE
} from "../../global/costants";
import MyBoldText from "../commons/myBoldText";
import ChatDetail from "./chatDetail";

function ChatFooter({
                        chatFooterRef,
                        rentUser,
                        activeChats,
                        openChat,
                        handleToggleChat,
                        handleDeleteChat,
                        maxWidth
                    }) {

    return (
        <div ref={chatFooterRef}
             style={{...pageStyle.container, width: maxWidth}}>
            <div style={{display: 'flex', flexDirection: 'row', overflowX: 'auto'}}>
                {activeChats.map(chat => {
                    const openThisChat = chat.chatId === openChat.chatId;
                    const chatStyle = {
                        backgroundColor: openThisChat ? THIRD_LEVEL_COLOR_OVER_WHITE : FIRST_LEVEL_COLOR_OVER_WHITE,
                        padding: 10,
                        marginRight: 10,
                        width: 120,
                        borderRadius: GLOBAL_BORDER_RADIUS,
                        boxShadow: GLOBAL_SHADOW
                    }
                    return (
                        <div key={chat.chatId}
                             style={{marginBottom: 10}}>
                            <ChatDetail rentUser={rentUser}
                                        chat={chat}
                                        handleCloseChat={handleToggleChat}
                                        isActive={chat.chatId === openChat.chatId}/>
                            <div style={chatStyle}>
                                <div onClick={() => handleToggleChat(chat)}
                                     style={pageStyle.chatFooterContainer}>
                                    <MyBoldText textOverflow={true}
                                                text={chat.body.client.name}/>
                                    <img src={require("../../imgs/actions/delete.png")}
                                         alt="delete"
                                         onClick={() => handleDeleteChat(chat)}
                                         style={pageStyle.actionOnChatStyle}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        alignItems: 'center',
        bottom: 0
    },
    chatFooterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        cursor: 'pointer'
    }
}

export default ChatFooter
