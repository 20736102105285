import React, {useEffect, useRef, useState} from "react";
import MainButton from "../commons/mainButton";
import {
    colorStatus,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    orderStatus,
    PRIMARY_COLOR,
    PRIMARY_COLOR_SECOND_ALTERNATIVE
} from "../../global/costants";
import Loader from "../commons/loader";
import {dashboardPageStyle} from "../dashboard/dashboardPageStyle";
import MyNormalText from "../commons/myNormalText";
import {formatUnixDate} from "../../global/functions";
import ItemBox from "../item/itemBox";
import MyBoldText from "../commons/myBoldText";
import CommonPopup from "../commons/commonPopup";

function ManualBookingDetail({
                                 rentUser,
                                 booking,
                                 loadingBooking,
                                 setIsOpen,
                                 handleSwitchToUpdateManualBooking,
                                 handleDeleteManualBooking
                             }) {

    const [openPopupDeleteBooking, setOpenPopupDeleteBooking] = useState(false)
    const dateOrderTimeStamp = new Date(booking?.timeStampInsertDate?._seconds * 1000)
    const bookingDetailRef = useRef()

    const isSingleDayRent = booking.isSingleDayRent
    const singleDaySlot = booking.orderDates?.singleDaySlot

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bookingDetailRef.current && !bookingDetailRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                setIsOpen(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, [])

    function getStatus() {
        if (booking.status === orderStatus.ACCEPTED) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.ACCEPTED}}}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="total-refund"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Stato</span>
                        <MyNormalText text={'Accettato'}/>
                    </div>
                </div>
            )
        }
    }

    function getOrderDates() {
        if (booking.orderDates.to) {
            return (
                <div style={pageStyle.bookingInfo}>
                    <img src={require("../../imgs/booking/calendar.png")}
                         alt="dates"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <span style={pageStyle.bookingInfoTitle}>Date richieste</span>
                        <MyNormalText text={'da ' + booking.orderDates.from + ' a ' + booking.orderDates.to}/>
                    </div>
                </div>
            )
        }

        return (
            <div style={pageStyle.bookingInfo}>
                <img src={require("../../imgs/booking/calendar.png")}
                     alt="dates"
                     style={pageStyle.imageInfo}/>
                <div style={pageStyle.columnInfo}>
                    <span style={pageStyle.bookingInfoTitle}>Data richiesta</span>
                    <MyNormalText text={booking.orderDates.from}/>
                </div>
            </div>
        )
    }

    if (!loadingBooking && !booking.bookingId) {
        return (
            <div style={{
                ...pageStyle.container,
                backgroundColor: HIGHLIGHT_COLOR,
                padding: 20,
                borderRadius: GLOBAL_BORDER_RADIUS
            }}>
                <div style={dashboardPageStyle.dashboardMainDiv}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <MyNormalText text={'404 booking not found'}/>
                        <img src={require("../../imgs/actions/delete.png")}
                             alt="delete"
                             onClick={() => setIsOpen(false)}
                             style={pageStyle.actionOnChatStyle}/>
                    </div>
                </div>
            </div>
        )
    }

    function createSlotDescription() {
        return singleDaySlot?.from + ' - ' + singleDaySlot?.to + ' (' + singleDaySlot?.description + ')'
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingBooking ?
                <Loader color={'white'}
                        absoluteFullPage={true}/> :
                <div ref={bookingDetailRef}
                     style={pageStyle.container}>
                    <div style={pageStyle.booking}>
                        <div style={pageStyle.bannerType}>
                            <MyBoldText text={'Ordine manuale'}/>
                        </div>
                        <div style={pageStyle.bookingInfoContainer}>
                            {getStatus()}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/id.png")}
                                     alt="id"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Codice ordine</span>
                                    <MyNormalText text={booking.orderId}/>
                                </div>
                            </div>
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/user.png")}
                                     alt="user"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Ordine di</span>
                                    <MyNormalText text={booking.client.name}/>
                                </div>
                            </div>
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/email.png")}
                                     alt="email"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>E-Mail</span>
                                    <MyNormalText text={booking.client.email}/>
                                </div>
                            </div>
                            {getOrderDates()}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/send.png")}
                                     alt="senttime"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Inviato il</span>
                                    <MyNormalText text={formatUnixDate(dateOrderTimeStamp)}/>
                                </div>
                            </div>
                            {isSingleDayRent ?
                                <div style={pageStyle.bookingInfo}>
                                    <img src={require("../../imgs/booking/single-day-rent.png")}
                                         alt="dates"
                                         style={pageStyle.imageInfo}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyBoldText text={'Fascia oraria'}/>
                                        <MyNormalText text={createSlotDescription()}/>
                                    </div>
                                </div> :
                                <></>}
                            <div style={pageStyle.bookingInfo}>
                                <img src={require("../../imgs/booking/detail.png")}
                                     alt="total"
                                     style={pageStyle.imageInfo}/>
                                <div style={pageStyle.columnInfo}>
                                    <span style={pageStyle.bookingInfoTitle}>Totale</span>
                                    <MyNormalText text={'€ ' + booking.total}/>
                                </div>
                            </div>
                        </div>
                        <div style={pageStyle.bookingInfo}>
                            <img src={require("../../imgs/booking/notes.png")}
                                 alt="language"
                                 style={pageStyle.imageInfo}/>
                            <div style={pageStyle.columnInfo}>
                                <span style={pageStyle.bookingInfoTitle}>Note</span>
                                <MyNormalText text={booking.notes}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {booking.items?.map(item => {
                                return (
                                    <div key={item.item.id}
                                         style={{marginRight: 20, marginBottom: 20}}>
                                        <ItemBox rentUser={rentUser}
                                                 item={item.item}
                                                 hidePrices={true}
                                                 openOperations={false}/>
                                    </div>
                                )
                            })}
                        </div>
                        {handleDeleteManualBooking && handleSwitchToUpdateManualBooking ?
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <MainButton text={'Cancella ordine'}
                                            action={() => setOpenPopupDeleteBooking(true)}
                                            backgroundColor={HIGHLIGHT_COLOR}/>
                                <div style={{width: 20}}>
                                </div>
                                <MainButton text={'Modifica ordine'}
                                            action={() => handleSwitchToUpdateManualBooking(booking)}
                                            backgroundColor={PRIMARY_COLOR}/>

                            </div> :
                            <></>}
                    </div>
                    {openPopupDeleteBooking ?
                        <CommonPopup message={'Sei sicuro di voler cancellare l\'ordine?'}
                                     firstButtonText={'Conferma'}
                                     firstButtonColor={HIGHLIGHT_COLOR}
                                     firstButtonAction={() => handleDeleteManualBooking(booking, true)}
                                     secondButtonText={'Annulla'}
                                     secondButtonColor={'lightGray'}
                                     secondButtonAction={() => setOpenPopupDeleteBooking(false)}
                                     setIsOpen={setOpenPopupDeleteBooking}/> :
                        <></>}
                </div>}
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        zIndex: 1
    },
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        overflowY: 'scroll'
    },
    booking: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_SECOND_ALTERNATIVE
    },
    bannerType: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    bookingInfoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    bookingInfo: {
        display: 'flex',
        backgroundColor: 'white',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginRight: 20,
        marginBottom: 20
    },
    bookingInfoTitle: {
        fontWeight: 'bold',
        fontSize: 20
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        marginLeft: 10,
        cursor: 'pointer'
    },
    imageInfo: {
        height: 26,
        width: 26
    },
    columnInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 5
    }
}

export default ManualBookingDetail
