import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import Footer from "../home/footer";
import MyMenu from "../menu/myMenu";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";
import Languages from "../home/languages";

function LocemApp({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <Languages languageState={languageState}/>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText text={'LoceM'}
                                fontSize={32}/>
                    <div style={pageStyle.sectionCellPhone}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{marginTop: 20, marginBottom: 40}}>
                                <div style={pageStyle.mainSubSectionCellPhoneAction}>
                                    <MyNormalText text={'CHOOSE'}
                                                  fontSize={22}/>
                                    <div style={{marginBottom: 10}}>
                                        <MyNormalText textWithTranslation={'locem.app.page.choose.text'}
                                                      language={languageState.language}/>
                                    </div>
                                </div>
                                <img style={pageStyle.cellPhoneImage}
                                     src={require("../../imgs/locemapp/choose.png")}
                                     alt="choose"/>
                            </div>
                            <div style={{marginTop: 20, marginBottom: 40}}>
                                <div style={pageStyle.mainSubSectionCellPhoneAction}>
                                    <MyNormalText text={'RENT'}
                                                  fontSize={22}/>
                                    <div style={{marginBottom: 10}}>
                                        <MyNormalText textWithTranslation={'locem.app.page.rent.text'}
                                                      language={languageState.language}/>
                                    </div>
                                </div>
                                <img style={pageStyle.cellPhoneImage}
                                     src={require("../../imgs/locemapp/rent.png")}
                                     alt="rent"/>
                            </div>
                            <div style={{marginTop: 20, marginBottom: 40}}>
                                <div style={pageStyle.mainSubSectionCellPhoneAction}>
                                    <MyNormalText text={'GO!'}
                                                  fontSize={22}/>
                                    <div style={{marginBottom: 10}}>
                                        <MyNormalText textWithTranslation={'locem.app.page.go.text'}
                                                      language={languageState.language}/>
                                    </div>
                                </div>
                                <img style={pageStyle.cellPhoneImage}
                                     src={require("../../imgs/locemapp/go.png")}
                                     alt="go"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.what.is.it'}
                                    fontSize={28}
                                    language={languageState.language}/>
                        <MyNormalText textWithTranslation={'locem.app.page.what.is.it.text'}
                                      language={languageState.language}/>
                    </div>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.for.adventures.lovers'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.for.adventures.lovers.text'}
                                      language={languageState.language}/>
                    </div>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.for.locel.business'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.for.locel.business.text'}
                                      language={languageState.language}/>
                    </div>
                    <div style={pageStyle.sectionInfoAppArticle}>
                        <MyBoldText textWithTranslation={'locem.app.page.always.present'}
                                    language={languageState.language}
                                    fontSize={28}/>
                        <MyNormalText textWithTranslation={'locem.app.page.always.present.text'}
                                      language={languageState.language}/>
                    </div>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

const pageStyle = {
    sectionCellPhone: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap'
    },
    cellPhoneImage: {
        borderRadius: 22,
        boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 1)",
        height: 460
    },
    logoDownload: {
        marginTop: 10,
        marginInline: 5,
        height: 60,
        width: 200
    },
    cellPhoneActionList: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 250,
        margin: 10
    },
    sectionInfoAppArticle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        marginTop: 20,
        padding: 5
    },
    mainSubSectionCellPhoneAction: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center'
    }
}

export default LocemApp;
