import moment from "moment/moment";

// MOMENT
export function now() {
    return moment()
}

export function toMomentDate(date) {
    return date ? moment(date, 'DD-MM-YYYY') : null
}

export function singleDayRentToMomentDate(date, hour) {
    const [giorno, mese, anno] = date.split('-').map(Number)
    const [ore, minuti] = hour.split('.').map(Number)
    const dateString = `${anno}-${mese}-${giorno} ${ore}:${minuti}`
    return moment(dateString, 'YYYY-MM-DD HH:mm')
}

export function fromMomentDate(date) {
    return date ? date.format('DD-MM-YYYY') : null
}

export function formatUnixDate(dateOrder) {
    if (dateOrder) {
        const year = dateOrder.getFullYear()
        const month = dateOrder.getMonth() + 1
        const date = dateOrder.getDate()
        const hour = dateOrder.getHours()
        const min = dateOrder.getMinutes()
        return date + '/' + month + '/' + year + ' ' + hour + ':' + (min > 9 ? min : '0' + min)
    }
    return ''
}
