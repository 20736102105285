import React from "react";
import {websitePages} from "../../global/costants";
import Header from "../header/header";
import {globalPageStyle} from "../../global/globalPageStyle";
import MyMenu from "../menu/myMenu";
import Footer from "../home/footer";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";

function LocemTermsOfServiceSpecific({languageState}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <MyMenu language={languageState.language}/>
            <Header pointer={true}
                    navigateTo={websitePages.HOME}/>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyBoldText text={'LOCEM APP'}
                                fontSize={30}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'CONDIZIONI PARTICOLARI DEI TERMINI D’USO PER L’UTENTE PRIVATO'}
                                fontSize={30}/>
                    <br/>
                    <br/>
                    <MyBoldText text={'(ARTT. 1341 E 1342 CODICE CIVILE ITALIANO)'}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'L’Utente Privato dichiara di aver preso attenta visione, compreso e integralmente accettato le disposizioni\n' +
                        'previste ai seguenti punti del Termini d’Uso (' + process.env.REACT_APP_WEBSITE_URL + '/app/locem/legal-terms):'}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'- PUNTO 5 (Durata, recesso, limitazione/sospensione/cessazione servizi, disattivazione account e\n' +
                        'cancellazione)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 7 (Esonero da responsabilità)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 8 (Manleva e indennizzo)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 9 (Modifiche ai Termini d’Uso)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 10 (Legge applicabile, giurisdizione e foro competente). '}/>
                </div>
            </main>
            <Footer language={languageState.language}/>
        </div>
    );
}

export default LocemTermsOfServiceSpecific;
