import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import LocemPrivacyPolicy from "./components/locemapp/locemPrivacyPolicy";
import Home from "./components/home/home";
import LocemTermsOfService from "./components/locemapp/locemTermsOfService";
import Login from "./components/login/login";
import Dashboard from "./components/dashboard/dashboard";
import {getRentUser} from "./global/user";
import Loader from "./components/commons/loader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import NotFound from "./components/notfound/notFound";
import {SECONDARY_COLOR} from "./global/costants";
import Help from "./components/help/help";
import LocemApp from "./components/locemapp/locemApp";
import LocemBusinessApp from "./components/locembusinessapp/locemBusinessApp";
import LocemBusinessPrivacyPolicy from "./components/locembusinessapp/locemBusinessPrivacyPolicy";
import LocemBusinessTermsOfService from "./components/locembusinessapp/locemBusinessTermsOfService";
import LocemWebsitePrivacyPolicy from "./components/locemwebsite/locemWebsitePrivacyPolicy";
import LocemTermsOfServiceSpecific from "./components/locemapp/locemTermsOfServiceSpecific";
import LegalNotes from "./components/legalNotes/legalNotes";
import Contacts from "./components/contacts/contacts";
import {httpsCallable} from "firebase/functions";
import {functions} from "./firebase";
import LocemWebsiteCookiesPolicy from "./components/locemwebsite/locemWebsiteCookiesPolicy";
import './css/myCalendar.css';
import './css/myReactTimeStyle.css';
import './css/input.css';
import '../src/css/myTimeline.css'
import '../src/css/scrollbar.css';

export const ITALIAN_LANGUAGE = 'italian'
export const ENGLISH_LANGUAGE = 'english'
export const GERMAN_LANGUAGE = 'german'

function App() {

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [language, setLanguage] = useState(getBrowserLanguage())
    const languageState = {language: language, setLanguage: setLanguage}

    function getBrowserLanguage() {
        const language = navigator.language
        if (language === 'it' || language === 'it-IT') {
            return ITALIAN_LANGUAGE
        } else if (language === 'de ' || language === 'de-DE') {
            return GERMAN_LANGUAGE
        }

        return ENGLISH_LANGUAGE
    }

    useEffect(() => {
        async function fetchUser() {
            const rentUser = await getRentUser()
            if (rentUser) {
                const getRentAppUser = httpsCallable(functions, 'getRentAppUser')
                getRentAppUser({rentId: rentUser.rentId})
                    .then(async (result) => {
                        const rentUser = result.data.rentUser
                        if (rentUser) {
                            setUser(rentUser)
                        }
                        setIsLoading(false)
                    }).catch((error) => {
                    setIsLoading(false)
                    alert(error.message)
                });
            } else {
                setIsLoading(false)
            }
        }

        fetchUser()
    }, [])

    const handleOnLogin = async (rentUser) => {
        setUser(rentUser)
        await AsyncStorage.setItem("user", JSON.stringify(rentUser))
    };

    const handleOnLogout = async () => {
        setUser(null)
        await AsyncStorage.setItem("user", JSON.stringify(null))
    }

    if (isLoading) {
        return <Loader absoluteFullPage={true}
                       color={SECONDARY_COLOR}/>
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home languageState={languageState}/>}/>
                <Route path="/app/locem" element={<LocemApp languageState={languageState}/>}/>
                <Route path="/app/locem/privacy" element={<LocemPrivacyPolicy languageState={languageState}/>}/>
                <Route path="/app/locem/legal-terms" element={<LocemTermsOfService languageState={languageState}/>}/>
                <Route path="/app/locem/legal-terms/specific" element={<LocemTermsOfServiceSpecific languageState={languageState}/>}/>
                <Route path="/app/locem-business" element={<LocemBusinessApp languageState={languageState}/>}/>
                <Route path="/app/locem-business/privacy" element={<LocemBusinessPrivacyPolicy languageState={languageState}/>}/>
                <Route path="/app/locem-business/legal-terms" element={<LocemBusinessTermsOfService languageState={languageState}/>}/>
                <Route path="/app/locem-website/privacy-policy" element={<LocemWebsitePrivacyPolicy languageState={languageState}/>}/>
                <Route path="/app/locem-website/cookies-policy" element={<LocemWebsiteCookiesPolicy languageState={languageState}/>}/>
                <Route path="/help" element={<Help languageState={languageState}/>}/>
                <Route path="/legal-notes" element={<LegalNotes languageState={languageState}/>}/>
                <Route path="/contacts" element={<Contacts languageState={languageState}/>}/>
                <Route path="/login"
                       element={!user ?
                           <Login handleOnLogin={handleOnLogin}
                                  languageState={{language: language, setLanguage: setLanguage}}/> :
                           <Navigate to="/"/>}/>
                <Route path="/dashboard"
                       element={user ?
                           <Dashboard rentUser={user}
                                      setRentUser={setUser}
                                      handleOnLogout={handleOnLogout}/> :
                           <Navigate to="/login"
                                     languageState={{language: language, setLanguage: setLanguage}}/>}/>
                <Route path="/dashboard/bookings/:idBooking"
                       element={user ?
                           <Dashboard rentUser={user}
                                      setRentUser={setUser}
                                      handleOnLogout={handleOnLogin}/> :
                           <Navigate to="/login"
                                     languageState={{language: language, setLanguage: setLanguage}}/>}/>
                <Route path="*" element={<NotFound languageState={{language: language, setLanguage: setLanguage}}/>}/>
            </Routes>
        </Router>
    )
}

export default App
