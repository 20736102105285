import React, {useState} from "react";
import Select from "react-select";
import Timeline from "react-calendar-timeline";
import Loader from "../commons/loader";
import {MAX_DIV_WIDTH_DASHBOARD, PRIMARY_COLOR} from "../../global/costants";
import {globalElementSelectStyle} from "../../global/globalElementSelectStyle";
import MainButton from "../commons/mainButton";

function MyTimeline({
                        items,
                        maxWidth,
                        timelineItems,
                        monthSelectedMoment,
                        monthYearStrings,
                        monthYearStringSelected,
                        loading,
                        handleFetchAcceptedBookingsAndParseThemForTimeline
                    }) {

    const timeTheCalendarCanZoomToInMillisecond = 60 * 60 * 1000 * 24

    const [expand, setExpand] = useState(true)

    function getItemListParsed() {
        function sortItemsByAssignedRentCode(items) {
            const itemsWithAssignedRentCode = items.filter(obj => obj.assignedRentCode).sort((a, b) => a.assignedRentCode.localeCompare(b.assignedRentCode))
            const itemsWithoutAssignedRentCode = items.filter(obj => !obj.assignedRentCode)
            return [...itemsWithAssignedRentCode, ...itemsWithoutAssignedRentCode]
        }

        return sortItemsByAssignedRentCode(items).map(item => {
            const itemTitle = (item.assignedRentCode ? ('[' + item.assignedRentCode + ']') : '[-]') + ' [' + item.size + '] ' + item.name
            return {
                id: item.id,
                title: itemTitle
            }
        })

    }

    const containerTimelineStyle = {
        height: expand ? undefined : 150,
        overflowY: 'auto'
    };

    function handlePressExpandTimeline() {
        setExpand(!expand)
    }

    return (
        <div style={pageStyle.container}>
            {loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <div style={{width: '100%'}}>
                <Select styles={globalElementSelectStyle}
                        placeholder={monthYearStringSelected ? monthYearStringSelected : 'Scegli un mese per visualizzare la timeline'}
                        onChange={(newValue) => handleFetchAcceptedBookingsAndParseThemForTimeline(false, newValue)}
                        options={monthYearStrings}/>
            </div>
            <div style={containerTimelineStyle}>
                {timelineItems.length > 0 ?
                    <Timeline groups={getItemListParsed()}
                              sidebarWidth={280}
                              items={timelineItems}
                              style={{maxWidth: maxWidth > MAX_DIV_WIDTH_DASHBOARD ? MAX_DIV_WIDTH_DASHBOARD - 30 : maxWidth - 60}}
                              defaultTimeStart={monthSelectedMoment.from}
                              defaultTimeEnd={monthSelectedMoment.to}
                              minZoom={timeTheCalendarCanZoomToInMillisecond}
                              maxZoom={timeTheCalendarCanZoomToInMillisecond}
                              canMove={false}
                              canResize={false}/>
                    : <></>}
            </div>
            {timelineItems.length > 0 ?
                <MainButton height={30}
                            image={expand ? require("../../imgs/home/arrow-up.png") : require("../../imgs/home/arrow-down.png")}
                            backgroundColor={PRIMARY_COLOR}
                            action={handlePressExpandTimeline}/>
                : <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }
}

export default MyTimeline
