export const globalElementSelectStyle = {
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        borderRadius: '5px',
        borderWidth: 0,
        boxShadow: state.isFocused ? '0 0 5px #333' : 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#333' : 'white',
        color: state.isSelected ? 'white' : '#333',
        padding: 10
    })
};
