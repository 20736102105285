import {GLOBAL_BORDER_RADIUS} from "./costants";

export const globalElementInputStyle = {
    customInput: {
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 10,
        fontSize: 18,
        color: "#333",
        outline: "none",
        minWidth: "100%",
        boxSizing: "border-box",
    }
}
