import {MAX_DIV_WIDTH_DASHBOARD} from "../../global/costants";

export const dashboardPageStyle = {
    dashboardPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        margin: 10,
        marginBottom: 100
    },
    dashboardMainDiv: {
        maxWidth: MAX_DIV_WIDTH_DASHBOARD,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%'
    }
}
