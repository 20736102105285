import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase";

export async function getUrlImg(idRent, itemName, path, imgName) {
    const imgRef = ref(storage, 'rent_' + idRent + '/' + itemName + '/' + path + '/' + imgName)
    return getDownloadURL(imgRef)
}

export async function getUrlCoverImg(idRent, imgName) {
    const imgRef = ref(storage, 'rent_' + idRent + '/cover/' + imgName)
    return getDownloadURL(imgRef)
}
