import React, {useEffect, useState} from "react";
import MyNormalText from "../commons/myNormalText";
import {EURO_VALUE, GLOBAL_BORDER_RADIUS, PRIMARY_COLOR, PRIMARY_COLOR_FIRST_ALTERNATIVE} from "../../global/costants";
import ButtonsToOrderItems from "./buttonsToOrderItems";
import MyBoldText from "../commons/myBoldText";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import ItemMinimalBox from "../item/itemMinimalBox";
import MainButton from "../commons/mainButton";
import ItemCostsPopup from "./itemCostsPopup";

function ItemsPremiumData({items, setItems, rentUser, setLoading}) {

    const [itemsPremiumData, setItemsPremiumData] = useState([])
    const [itemCostsToOpen, setItemCostsToOpen] = useState(null)
    const [openItemCostsPopup, setOpenItemCostsPopup] = useState(false)
    const [triggerRefreshData, setTriggerRefreshData] = useState(false)

    useEffect(() => {
        getItemsPremiumData()
    }, [triggerRefreshData])

    function getItemsPremiumData() {
        setLoading(true)
        const rentItemsPremiumDataListView = httpsCallable(functions, 'rentItemsPremiumDataListView')
        rentItemsPremiumDataListView({rentId: rentUser.rentId})
            .then((response) => {
                const premiumItemsData = response.data.premiumItemsData
                setItemsPremiumData(premiumItemsData)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                alert('Ooops! Sembra esserci stato un errore.')
            })
    }

    function handleOpenItemCostsPopup(item) {
        setOpenItemCostsPopup(!openItemCostsPopup)
        setItemCostsToOpen(item)
    }

    function reloadData() {
        setTriggerRefreshData(!triggerRefreshData)
    }

    return (
        <div>
            <ButtonsToOrderItems items={items}
                                 setItems={setItems}
                                 premiumDataButtons={true}
                                 buttonsColor={PRIMARY_COLOR}/>
            <div>
                {items.map(item => {
                    const premiumData = itemsPremiumData.filter(itemPremiumData => item.id === itemPremiumData.itemId)
                    let amount = 0
                    if (premiumData.length > 0) {
                        amount = premiumData.reduce((acc, curr) => {
                            return acc + curr.amount
                        }, 0)
                        item.premiumDataAmountProduced = amount
                    }
                    return (
                        <div key={item.id}>
                            <div style={pageStyle.row}>
                                <div style={{display: 'flex', flex: 1}}>
                                    <div style={{marginRight: 10}}>
                                        <ItemMinimalBox item={item}
                                                        rentUser={rentUser}/>
                                    </div>
                                    <div style={pageStyle.containerInfo}>
                                        <MyBoldText text={'Totale'}/>
                                        <MyNormalText text={EURO_VALUE + amount}/>
                                    </div>
                                </div>
                                <MainButton image={require("../../imgs/actions/repair.png")}
                                            width={50}
                                            action={() => handleOpenItemCostsPopup(item)}
                                            backgroundColor={PRIMARY_COLOR_FIRST_ALTERNATIVE}/>
                            </div>
                        </div>
                    )
                })}
                {openItemCostsPopup ?
                    <ItemCostsPopup item={itemCostsToOpen}
                                    rentId={rentUser.rentId}
                                    setOpen={setOpenItemCostsPopup}
                                    handleReloadData={reloadData}/> :
                    <></>}
            </div>
        </div>
    )
}

const pageStyle = {
    row: {
        display: 'flex',
        marginTop: 20,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR
    },
    containerInfo: {
        display: 'flex',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    },
    repairPng: {
        width: 35,
        height: 35
    }
}

export default ItemsPremiumData
