import React, {useEffect, useRef, useState} from "react";
import {
    bookingType,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    PRIMARY_COLOR_SECOND_ALTERNATIVE
} from "../../global/costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import {dashboardPageStyle} from "../dashboard/dashboardPageStyle";
import MyNormalText from "../commons/myNormalText";
import AutomaticBookingDetail from "./automaticBookingDetail";
import ManualBookingDetail from "./manualBookingDetail";

function BookingDetail({
                           rentUser,
                           bookingId,
                           isOpen,
                           setIsOpen,
                           handleSelectChat,
                           handleSwitchToUpdateManualBooking,
                           handleDeleteManualBooking
                       }) {

    const [booking, setBooking] = useState({})
    const [loadingBooking, setLoadingBooking] = useState(true)
    const [loadingAccept, setLoadingAccept] = useState(false)
    const [loadingRejected, setLoadingRejected] = useState(false)
    const [openRejectOptions, setOpenRejectOptions] = useState(false)
    const [commentRejection, setCommentRejection] = useState("")
    const bookingDetailRef = useRef()

    const isAnAutomaticBooking = booking.type === bookingType.SYSTEM_AUTOMATIC

    useEffect(() => {
        getBookingDetail()
    }, [bookingId, isOpen])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bookingDetailRef.current && !bookingDetailRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                setIsOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    function getBookingDetail() {
        setLoadingBooking(true)
        if (bookingId && isOpen) {
            const getRentBookingDetail = httpsCallable(functions, 'getRentBookingDetail')
            let request = {rentId: rentUser.rentId, bookingId: bookingId}
            getRentBookingDetail(request)
                .then((response) => {
                    const booking = response.data.booking
                    setBooking(booking)
                    setLoadingBooking(false)
                }).catch(() => setLoadingBooking(false))
        }
    }

    if (!isOpen) {
        return null
    }

    function acceptBooking() {
        setLoadingAccept(true)
        const createAcceptOrder = httpsCallable(functions, 'createAcceptOrder')
        createAcceptOrder({
            bookingId: booking.bookingId,
            rentId: rentUser.rentId
        }).then((response) => {
            const booking = response.data.booking
            setBooking(booking)
            setLoadingAccept(false)
        }).catch(() => {
            setLoadingAccept(false)
            alert('Ooops! Sembra esserci stato un errore nell\'accettare l\'ordine.')
        })
    }

    function rejectBooking() {
        setOpenRejectOptions(false)
        setLoadingRejected(true)
        const createRejectOrder = httpsCallable(functions, 'createRejectOrder')
        createRejectOrder({
            bookingId: booking.bookingId,
            rentId: rentUser.rentId,
            motivationRejection: commentRejection
        }).then((response) => {
            const booking = response.data.booking
            setBooking(booking)
            setLoadingRejected(false)
        }).catch(() => {
            setLoadingRejected(false)
            alert('Ooops! Sembra esserci stato un errore nel rifiutare l\'ordine.')
        })
    }

    if (!loadingBooking && !booking.bookingId) {
        return (
            <div style={{
                ...pageStyle.container,
                backgroundColor: HIGHLIGHT_COLOR,
                padding: 20,
                borderRadius: GLOBAL_BORDER_RADIUS
            }}>
                <div style={dashboardPageStyle.dashboardMainDiv}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <MyNormalText text={'404 booking not found'}/>
                        <img src={require("../../imgs/actions/delete.png")}
                             alt="delete"
                             onClick={() => setIsOpen(false)}
                             style={pageStyle.actionOnChatStyle}/>
                    </div>
                </div>
            </div>
        )
    }

    if (isAnAutomaticBooking) {
        return <AutomaticBookingDetail booking={booking}
                                       rentUser={rentUser}
                                       loadingBooking={loadingBooking}
                                       acceptBooking={acceptBooking}
                                       rejectBooking={rejectBooking}
                                       loadingAccept={loadingAccept}
                                       loadingRejected={loadingRejected}
                                       handleSelectChat={handleSelectChat}
                                       commentRejection={commentRejection}
                                       setCommentRejection={setCommentRejection}
                                       openRejectOptions={openRejectOptions}
                                       setOpenRejectOptions={setOpenRejectOptions}
                                       setIsOpen={setIsOpen}/>
    }

    return <ManualBookingDetail booking={booking}
                                rentUser={rentUser}
                                loadingBooking={loadingBooking}
                                handleSwitchToUpdateManualBooking={handleSwitchToUpdateManualBooking}
                                handleDeleteManualBooking={handleDeleteManualBooking}
                                setIsOpen={setIsOpen}/>
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
        zIndex: 1
    },
    booking: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_SECOND_ALTERNATIVE
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        marginLeft: 10,
        cursor: 'pointer'
    }
}

export default BookingDetail
