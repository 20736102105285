import React from "react";
import MyNormalText from "../commons/myNormalText";
import {GLOBAL_BORDER_RADIUS, GLOBAL_SHADOW, HIGHLIGHT_COLOR} from "../../global/costants";

function ChatList({chatRef, chats, handleActivateChat}) {

    function getActiveChats() {
        if (chats.length === 0) {
            return (
                <div style={{margin: 10}}>
                    <MyNormalText text={'Nessuna chat attiva'}/>
                </div>
            )
        }

        return <>
            {chats.map(chat => {
                const chatBody = chat.body;
                const chatStyle = {
                    ...pageStyle.chat,
                    backgroundColor: chat.newMessagesAmount > 0 ? HIGHLIGHT_COLOR : 'white'
                }
                return (
                    <div key={chat.chatId}
                         style={chatStyle}>
                        <div style={pageStyle.infoContainer}>
                            <div onClick={() => handleActivateChat(chat)}
                                 style={pageStyle.baseInfo}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                                        <img src={require("../../imgs/actions/user.png")}
                                             alt="chat"
                                             style={{width: 18, height: 18, marginRight: 10}}/>
                                        <div style={pageStyle.singleChatContainer}>
                                            <MyNormalText text={chatBody.client.name}
                                                          textOverflow={true}/>
                                        </div>
                                    </div>
                                    {chat.newMessagesAmount > 0 ?
                                        <MyNormalText text={chat.newMessagesAmount}/> :
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>;
    }

    return (
        <div ref={chatRef}
             style={pageStyle.container}>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                {getActiveChats()}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 800
    },
    chat: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        boxShadow: GLOBAL_SHADOW,
        borderRadius: GLOBAL_BORDER_RADIUS,
        margin: 6,
        marginRight: 2
    },
    infoContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    singleChatContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    baseInfo: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: GLOBAL_BORDER_RADIUS,
        padding: 5
    }
}

export default ChatList
