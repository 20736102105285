import React, {useState} from "react";
import {globalPageStyle} from "../../global/globalPageStyle";
import {websitePages} from "../../global/costants";
import Footer from "./footer";
import Loader from "../commons/loader";
import Header from "../header/header";
import MyMenu from "../menu/myMenu";
import WhoWeAre from "./whoWeAre";
import TheyTalkAboutUs from "./theyTalkAboutUs";
import Languages from "./languages";
import MyBoldText from "../commons/myBoldText";

function Home({languageState}) {

    const [videoNotReady, setVideoNotReady] = useState(true)

    const textShadow = '-0.8px 0 white, 0 0.8px white, 0.8px 0 white, 0 -0.8px white'

    return (
        <>
            {videoNotReady ? <Loader absoluteFullPage={true}/> : <></>}
            <div style={{height: '100vh'}}>
                <Languages languageState={languageState}/>
                <MyMenu language={languageState.language}/>
                <video onCanPlay={() => setVideoNotReady(false)}
                       muted
                       autoPlay
                       playsInline
                       loop
                       style={pageStyle.videoBackground}
                       src={'background-video.mp4'}/>
                <div style={pageStyle.containerAbsoluteVideo}
                     id={'scarica-app'}>
                    <main style={globalPageStyle.main}>
                        <div style={{height: '100%'}}>
                            <div style={pageStyle.sectionVideoIntro}>
                                <Header navigateTo={websitePages.HOME}
                                        pointer={false}/>
                            </div>
                            <div style={pageStyle.sectionVideoIntro}>
                                <MyBoldText textWithTranslation={'home.locem.has.arrived'}
                                            language={languageState.language}
                                            textShadow={textShadow}
                                            fontSize={28}/>
                                <div style={{display: "flex", justifyContent: 'center', flexWrap: 'wrap'}}>
                                    <img onClick={() => window.open('https://apps.apple.com/us/app/locem/id6449721104')}
                                         src={require("../../imgs/home/apple-store.png")}
                                         alt="Download on the App Store"
                                         style={pageStyle.logoDownload}/>
                                    <img
                                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.locemsolutions.locem')}
                                        src={require("../../imgs/home/google-play-store.png")}
                                        alt="Get it on Google Play"
                                        style={pageStyle.logoDownload}/>
                                </div>
                            </div>
                        </div>
                    </main>
                    <div style={pageStyle.linkLocemScopriDiPiu}>
                        <MyBoldText textWithTranslation={'home.locem.app.for.your.rental'}
                                    language={languageState.language}
                                    textShadow={textShadow}
                                    fontSize={18}/>
                        <MyBoldText textWithTranslation={'home.locem.learn.mode'}
                                    language={languageState.language}
                                    link={'/app/locem'}
                                    textShadow={textShadow}
                                    fontSize={18}/>
                    </div>
                </div>
                <div id={'chi-siamo'}
                     style={pageStyle.containerHomeInfo}>
                    <WhoWeAre language={languageState.language}/>
                </div>
                <div id={'parlano-di-noi'}
                     style={pageStyle.containerHomeInfo}>
                    <TheyTalkAboutUs language={languageState.language}/>
                </div>
                <Footer language={languageState.language}/>
            </div>
        </>
    )
}

const pageStyle = {
    containerAbsoluteVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    videoBackground: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    },
    logoDownload: {
        marginTop: 10,
        marginInline: 5,
        height: 60,
        width: 200
    },
    containerHomeInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    sectionVideoIntro: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        paddingInline: 10
    },
    linkLocemScopriDiPiu: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        margin: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    section: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: 60,
        paddingBottom: 60,
        textAlign: 'center'
    },
    subSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 10,
        padding: 10
    }
}

export default Home
