import React, {useState} from "react";
import {
    bookingType,
    colorStatus,
    colorStatusOverlay,
    GLOBAL_BORDER_RADIUS,
    GLOBAL_SHADOW,
    orderStatus
} from "../../global/costants";
import {formatUnixDate} from "../../global/functions";
import MyNormalText from "../commons/myNormalText";
import BookingDetail from "./bookingDetail";
import MyBoldText from "../commons/myBoldText";

function BookingRow({rentUser, booking, handleSelectChat}) {

    const [openBookingDetail, setOpenBookingDetail] = useState(false)

    const isAnAutomaticBooking = booking.type === bookingType.SYSTEM_AUTOMATIC
    const unixDate = booking?.timeStampInsertDate?.seconds ? booking?.timeStampInsertDate?.seconds : booking?.timeStampInsertDate?._seconds
    const dateOrderTimeStamp = new Date(unixDate * 1000)

    const aDiscountWasApplied = booking.discount
    const isSingleDayRent = booking.isSingleDayRent
    const singleDaySlot = booking.orderDates.singleDaySlot

    function getBookingTextDates(orderDates) {
        if (orderDates.to) {
            return <MyNormalText text={'da ' + orderDates.from + ' a ' + orderDates.to}/>
        }
        return <MyNormalText text={'per il giorno ' + orderDates.from}/>
    }

    function handleClick() {
        setOpenBookingDetail(true)
    }

    function getBookingStyle() {
        if (booking.status === orderStatus.ACCEPTED) {
            return {...pageStyle.booking, ...{backgroundColor: colorStatus.ACCEPTED}};
        } else if (booking.status === orderStatus.REJECTED) {
            return {...pageStyle.booking, ...{backgroundColor: colorStatus.REJECTED}};
        } else if (booking.status === orderStatus.PENDING) {
            return {...pageStyle.booking, ...{backgroundColor: colorStatus.PENDING}};
        } else if (booking.status === orderStatus.REFUNDED) {
            return {...pageStyle.booking, ...{backgroundColor: colorStatus.REFUNDED}};
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return {...pageStyle.booking, ...{backgroundColor: colorStatus.CANCELED_FROM_SYSTEM}};
        }
    }

    function getStatusDescription() {
        if (booking.status === orderStatus.ACCEPTED) {
            return <MyNormalText text={'Accettato'}/>
        } else if (booking.status === orderStatus.REJECTED) {
            return <MyNormalText text={'Rifiutato'}/>
        } else if (booking.status === orderStatus.PENDING) {
            return <MyNormalText text={'Da confermare'}/>
        } else if (booking.status === orderStatus.REFUNDED) {
            return <MyNormalText text={'Rimborsato'}/>
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return <MyNormalText text={'Cancellato (scadute 24h)'}/>
        }
    }

    function getBackgroundOverlayFromBookingStatus() {
        if (booking.status === orderStatus.ACCEPTED) {
            return {backgroundColor: colorStatusOverlay.ACCEPTED};
        } else if (booking.status === orderStatus.REJECTED) {
            return {backgroundColor: colorStatusOverlay.REJECTED};
        } else if (booking.status === orderStatus.PENDING) {
            return {backgroundColor: colorStatusOverlay.PENDING};
        } else if (booking.status === orderStatus.REFUNDED) {
            return {backgroundColor: colorStatusOverlay.REFUNDED};
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return {backgroundColor: colorStatusOverlay.CANCELED_FROM_SYSTEM};
        }
    }

    pageStyle.bookingInfoSection = {...pageStyle.bookingInfoSection, ...getBackgroundOverlayFromBookingStatus(booking.status)}

    function singleDayRentDescription() {
        return singleDaySlot.from + ' - ' + singleDaySlot.to + ' (' + singleDaySlot.description + ')'
    }

    return (
        <div style={getBookingStyle()}
             key={booking.bookingId}>
            <div style={{...pageStyle.bannerType, ...getBackgroundOverlayFromBookingStatus()}}>
                {isAnAutomaticBooking ?
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{marginRight: 10}}>
                            <MyBoldText text={'App LoceM'}/>
                        </div>
                        <img src={require("../../imgs/booking/logo.png")}
                             alt="Status"
                             style={pageStyle.imageInfo}/>
                    </div> :
                    <MyBoldText text={'Ordine manuale'}/>}
            </div>
            <div onClick={handleClick}
                 style={pageStyle.bookingInfo}>
                <div style={pageStyle.bookingInfoSection}>
                    <img src={require("../../imgs/booking/status.png")}
                         alt="Status"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <MyBoldText text={'Stato'}/>
                        {getStatusDescription()}
                    </div>
                </div>
                <div style={pageStyle.bookingInfoSection}>
                    <img src={require("../../imgs/booking/id.png")}
                         alt="Id"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <MyBoldText text={'Codice ordine'}/>
                        <MyNormalText text={booking.orderId}/>
                    </div>
                </div>
                <div style={pageStyle.bookingInfoSection}>
                    <img src={require("../../imgs/booking/user.png")}
                         alt="client"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <MyBoldText text={'Ordine in arriva da'}/>
                        <MyNormalText text={booking.client.name}/>
                    </div>
                </div>
                <div style={pageStyle.bookingInfoSection}>
                    <img src={require("../../imgs/booking/calendar.png")}
                         alt="dates"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <MyBoldText text={'Date richieste'}/>
                        <div style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                            {getBookingTextDates(booking.orderDates)}
                        </div>
                    </div>
                </div>
                {isSingleDayRent ?
                    <div style={pageStyle.bookingInfoSection}>
                        <img src={require("../../imgs/booking/single-day-rent.png")}
                             alt="dates"
                             style={pageStyle.imageInfo}/>
                        <div style={pageStyle.columnInfo}>
                            <MyBoldText text={'Fascia oraria'}/>
                            <MyNormalText text={singleDayRentDescription()}/>
                        </div>
                    </div> :
                    <></>}
                <div style={pageStyle.bookingInfoSection}>
                    <img src={require("../../imgs/booking/send.png")}
                         alt="sendtime"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <MyBoldText text={'Inviato il'}/>
                        <MyNormalText text={formatUnixDate(dateOrderTimeStamp)}/>
                    </div>
                </div>
                {aDiscountWasApplied ?
                    <div style={pageStyle.bookingInfoSection}>
                        <img src={require("../../imgs/booking/discount.png")}
                             alt="total"
                             style={pageStyle.imageInfo}/>
                        <div style={pageStyle.columnInfo}>
                            <MyBoldText text={'Totale scontato'}/>
                            <MyNormalText text={'€ ' + booking.discount.totalDiscounted}/>
                        </div>
                    </div>
                    :
                    <></>}
                <div style={pageStyle.bookingInfoSection}>
                    <img src={require("../../imgs/booking/detail.png")}
                         alt="total"
                         style={pageStyle.imageInfo}/>
                    <div style={pageStyle.columnInfo}>
                        <MyBoldText text={'Totale'}/>
                        <MyNormalText text={'€ ' + booking.total}/>
                    </div>
                </div>
                {booking.status === orderStatus.REFUNDED ?
                    <div style={pageStyle.bookingInfoSection}>
                        <img src={require("../../imgs/booking/refund.png")}
                             alt="total-refund"
                             style={pageStyle.imageInfo}/>
                        <div style={pageStyle.columnInfo}>
                            <MyBoldText text={'Totale rimborsato'}/>
                            <MyNormalText text={'€ ' + booking.totalRefunded}/>
                        </div>
                    </div>
                    : <></>
                }
            </div>
            <BookingDetail rentUser={rentUser}
                           bookingId={booking.bookingId}
                           isOpen={openBookingDetail}
                           setIsOpen={setOpenBookingDetail}
                           handleSelectChat={handleSelectChat}/>
        </div>
    )
}

const pageStyle = {
    booking: {
        cursor: 'pointer',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        boxShadow: GLOBAL_SHADOW
    },
    bannerType: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10
    },
    bookingInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    bookingInfoSection: {
        display: 'flex',
        marginRight: 10,
        marginTop: 10,
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    textInfo: {
        fontSize: 18
    },
    columnInfo: {
        display: "flex",
        flexDirection: 'column',
        marginLeft: 5
    },
    imageInfo: {
        height: 32,
        width: 32
    }
}

export default BookingRow
