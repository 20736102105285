import React, {useState} from "react";
import {slide as Menu} from "react-burger-menu";
import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../global/costants";
import MyNormalText from "../commons/myNormalText";

function MyMenu({language}) {

    const [openMenu, setOpenMenu] = useState(false)

    const burgerMenuSection = {marginBottom: 30}

    return (
        <Menu customCrossIcon={false}
              right width={260}
              isOpen={openMenu}
              onClose={() => setOpenMenu(false)}
              onOpen={() => setOpenMenu(true)}
              styles={pageStyle.headerBurgerMenu}>
            <div onClick={() => setOpenMenu(false)}
                 style={burgerMenuSection}>
                <MyNormalText textWithTranslation={'menu.download.the.app'}
                              language={language}
                              hideUnderlainLink={true}
                              link={'/#scarica-app'}/>
            </div>
            <div onClick={() => setOpenMenu(false)}
                 style={burgerMenuSection}>
                <MyNormalText textWithTranslation={'menu.about.us'}
                              language={language}
                              hideUnderlainLink={true}
                              link={'/#chi-siamo'}/>
            </div>
            <div onClick={() => setOpenMenu(false)}
                 style={burgerMenuSection}>
                <MyNormalText textWithTranslation={'menu.what.they.say.about.us'}
                              language={language}
                              hideUnderlainLink={true}
                              link={'/#parlano-di-noi'}/>
            </div>
            <div style={burgerMenuSection}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <MyNormalText textWithTranslation={'menu.products'}
                                  language={language}
                                  hideUnderlainLink={true}
                                  link={''}/>
                    <img src={require("../../imgs/home/arrow-down.png")}
                         alt="arrow down"
                         style={{height: 22, width: 22, marginLeft: 5}}/>
                </div>
                <li onClick={() => setOpenMenu(false)}>
                    <MyNormalText text={'LoceM'}
                                  hideUnderlainLink={true}
                                  link={'/app/locem'}/>
                </li>
                <li onClick={() => setOpenMenu(false)}>
                    <MyNormalText text={'LoceM Business'}
                                  hideUnderlainLink={true}
                                  link={'/app/locem-business'}/>
                </li>
            </div>
            <div style={burgerMenuSection}>
                <MyNormalText textWithTranslation={'menu.reserved.area'}
                              language={language}
                              hideUnderlainLink={true}
                              link={'/dashboard'}/>
            </div>
        </Menu>
    )
}

const pageStyle = {
    headerBurgerMenu: {
        bmBurgerButton: {
            position: 'fixed',
            width: 36,
            height: 30,
            right: 25,
            top: 35
        },
        bmBurgerBars: {
            background: SECONDARY_COLOR
        },
        bmBurgerBarsHover: {
            background: PRIMARY_COLOR
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: PRIMARY_COLOR
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: PRIMARY_COLOR,
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
            overflow: undefined
        },
        bmMorphShape: {
            fill: PRIMARY_COLOR
        },
        bmItemList: {
            color: PRIMARY_COLOR,
            padding: '0.8em'
        },
        bmItem: {
            display: 'inline-block'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }
}

export default MyMenu;
