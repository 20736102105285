import React from "react";
import {globalPageStyle} from "../../global/globalPageStyle";
import {GLOBAL_BORDER_RADIUS, MAX_DIV_WIDTH_HOME, PRIMARY_COLOR_THIRD_ALTERNATIVE} from "../../global/costants";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";

function TheyTalkAboutUs({language}) {

    const FONT_SIZE_TITLE = 26
    const URL_DOLOMITI = 'https://www.ildolomiti.it/societa/2024/vivere-le-bellezze-del-garda-appieno-e-senza-stress-ecco-locem-lapp-che-facilita-il-noleggio-di-biciclette-e-attrezzature'

    const link = {textDecoration: 'none', color: 'black', cursor: 'pointer'}

    return (
        <main style={{...globalPageStyle.main, ...{backgroundColor: PRIMARY_COLOR_THIRD_ALTERNATIVE}}}>
            <div style={pageStyle.section}>
                <MyBoldText textWithTranslation={'home.what.they.say.about.us'}
                            language={language}
                            fontSize={FONT_SIZE_TITLE}/>
            </div>
            <div style={pageStyle.section}>
                <a href={URL_DOLOMITI}
                   style={link}>
                    <div style={pageStyle.sectionArticle}>
                        <MyNormalText text={'Il Dolomiti'}
                                      fontSize={FONT_SIZE_TITLE}/>
                        <div style={{marginBottom: 10}}>
                            <img src={require("../../imgs/home/dolomiti-article.jpg")}
                                 alt="article-dolomiti-preview"
                                 style={pageStyle.imageInfo}/>
                        </div>
                        <MyBoldText textWithTranslation={'home.what.they.say.about.us.article.title'}
                                    language={language}/>
                        <MyNormalText textWithTranslation={'home.what.they.say.about.us.article.text'}
                                      language={language}/>
                    </div>
                </a>
            </div>
        </main>
    );
}

const pageStyle = {
    section: {
        maxWidth: MAX_DIV_WIDTH_HOME,
        padding: 20,
        textAlign: 'center'
    },
    sectionArticle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 300
    },
    imageInfo: {
        width: 200,
        height: 100,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default TheyTalkAboutUs;
