import React, {useEffect, useRef, useState} from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import moment from "moment";
import {httpsCallable} from "firebase/functions";
import {db, functions} from "../../firebase";
import MainButton from "../commons/mainButton";
import MyTimeline from "./myTimeline";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {
    GLOBAL_BORDER_RADIUS,
    orderStatus,
    PREMIUM_COLOR,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE,
    SECONDARY_COLOR,
    subscriptionType
} from "../../global/costants";
import NewBookings from "./newBookings";
import MyItems from "./myItems";
import SearchBookings from "./searchBookings";
import BookingHistory from "./bookingHistory";
import Profile from "../profile/profile";
import {dashboardPageStyle} from "./dashboardPageStyle";
import ChatList from "../chat/chatList";
import ChatFooter from "../chat/chatFooter";
import {useParams} from "react-router-dom";
import BookingDetail from "../booking/bookingDetail";
import {fromMomentDate, now, toMomentDate} from "../../global/functions";
import MyBoldText from "../commons/myBoldText";
import HeaderDashboard from "../header/headerDashboard";
import MyNormalText from "../commons/myNormalText";
import Premium from "../premium/premium";

const monthNames = {
    it: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ]
};

const dashboardSection = {
    NEW_BOOKINGS: 'MY BOOKINGS',
    HISTORY: 'HISTORY',
    SEARCH: 'SEARCH',
    MY_ITEMS: 'MY ITEMS',
    PROFILE: 'PROFILE',
    PREMIUM: 'PREMIUM'
};

moment.updateLocale('it', {
    months: monthNames.it,
    weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']
});

function Dashboard({rentUser, setRentUser, handleOnLogout}) {

    const isAccountPremium = rentUser.subscriptionType === subscriptionType.PREMIUM

    //dashboard button selected
    const [sectionSelected, setSectionSelected] = useState(dashboardSection.NEW_BOOKINGS);

    //new bookings incoming
    const [newBookings, setNewBookings] = useState([]);

    //bookings timeline
    const [parsedItems, setParsedItems] = useState([]);
    const [rangeSelectedMoment, setRangeSelectedMoment] = useState({
        from: now(),
        to: now().add(1, 'month')
    })
    const [loadingTimelineBookings, setLoadingTimelineBookings] = useState(false);
    const [monthYearHistory, setMonthYearHistory] = useState([]);
    const monthYearSelectedForTimelineInitialValue = "";
    const [monthYearStringSelectedForTimeline, setMonthYearStringSelectedForTimeline] = useState(monthYearSelectedForTimelineInitialValue)
    const timelineIsOpened = monthYearStringSelectedForTimeline !== monthYearSelectedForTimelineInitialValue

    //bookings history
    const [bookingsHistory, setBookingsHistory] = useState([]);
    const [monthYearStringSelectedForHistory, setMonthYearStringSelectedForHistory] = useState("");

    // search history
    const [searchValue, setSearchValue] = useState("");
    const [bookingsSearched, setBookingsSearched] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);

    // my items
    const [itemsForPremiumSection, setItemsForPremiumSection] = useState([])
    const [itemsForMyTimeline, setItemsForMyTimeline] = useState([])
    const [itemsForMyItems, setItemsForMyItems] = useState([])
    const [itemsWithArchivedForMyItems, setItemsWithArchivedForMyItems] = useState([])
    const [loadingItems, setLoadingItems] = useState(true)

    // property to refresh items
    const [refreshItems, setRefreshItems] = useState(false)

    // chat
    const [chats, setChats] = useState([]);
    const chatListRef = useRef(null);
    const chatFooterRef = useRef(null);
    const [activeChats, setActiveChats] = useState([])
    let openChatInitialValue = {};
    const [openChat, setOpenChat] = useState(openChatInitialValue)

    // booking detail
    //prendi il booking dai params dell'url
    const {idBooking} = useParams();
    const [bookingIdDetailToOpen, setBookingIdDetailToOpen] = useState(idBooking)
    const [openBookingDetail, setOpenBookingDetail] = useState(!!idBooking)

    useEffect(() => {
        const unsubscribeToNewBookings = subscribeToNewBookings();
        const unsubscribeToChats = subscribeToNewChat();
        getRentMonthYearBookingHistory();
        return () => {
            unsubscribeToNewBookings()
            unsubscribeToChats()
        };
    }, [])

    useEffect(() => {
        getitems()
    }, [refreshItems])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatFooterRef.current && !chatFooterRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                setOpenChat(openChatInitialValue)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);


    const [pageWidth, setPageWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handlePageScale = () => setPageWidth(window.innerWidth);
        window.addEventListener('resize', handlePageScale);
        return () => window.removeEventListener('resize', handlePageScale);
    }, []);

    function subscribeToNewBookings() {
        const q = query(collection(db, "rent_app_new_bookings", rentUser.rentId, "bookings"),
            where("status", "==", orderStatus.PENDING))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const bewBookings = []
            querySnapshot.forEach((doc) => {
                bewBookings.push(doc.data())
            })
            setNewBookings(bewBookings)
        })
        return () => unsubscribe()
    }

    function subscribeToNewChat() {
        const today = moment().toDate()
        const q = query(collection(db, "rent_app_my_chats", rentUser.rentId, "chats"),
            where('endDate', '>', today),
            where('isActive', '==', true))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chats = []
            let newMessages = 0
            querySnapshot.forEach((doc) => {
                const newChat = doc.data();
                newMessages += newChat.newMessagesAmount
                chats.push(newChat)
            })
            setChats(chats)
        })

        return () => unsubscribe()
    }

    function getRentMonthYearBookingHistory() {
        const getRentMonthYearBookingHistory = httpsCallable(functions, 'getRentMonthYearBookingHistory');
        getRentMonthYearBookingHistory({rentId: rentUser.rentId})
            .then((result) => {
                const monthYearList = result.data.monthYearList;
                setMonthYearHistory(monthYearList)
            })
    }

    function getitems() {
        const rentItemsListView = httpsCallable(functions, 'rentItemsListView')
        rentItemsListView({
            rentId: rentUser.rentId,
            archived: true
        }).then((result) => {
            const items = result.data.items

            function sortArchivedAtTheEnd() {
                return items.sort((a, b) => {
                    if (a.archived && !b.archived)
                        return 1
                    else if (!a.archived && b.archived)
                        return -1
                    return 1
                })
            }

            const itemsNotArchived = items.filter(item => !item.archived)
            setItemsForPremiumSection(itemsNotArchived)
            setItemsForMyTimeline(itemsNotArchived)
            setItemsForMyItems(itemsNotArchived)

            const itemsWithArchived = sortArchivedAtTheEnd().filter(item => item.archived)
            setItemsWithArchivedForMyItems(itemsWithArchived)

            setLoadingItems(false)
        }).catch(() => setLoadingItems(false))
    }

    function changeSection(section) {
        setSectionSelected(section)
    }

    function getMonthYearStrings() {
        function getMonthName(monthId) {
            const monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
            return monthNames[monthId]
        }

        let result = []
        if (monthYearHistory.length > 0) {
            for (let value of monthYearHistory) {
                let [month, year] = value.split('-')
                const date = new Date(year, month - 1, 1)
                const monthYearString = getMonthName(date.getMonth()) + ' ' + date.getFullYear()
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
                const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                const firstDayString = `${firstDay.getDate()}-${firstDay.getMonth() + 1}-${firstDay.getFullYear()}`
                const lastDayString = `${lastDay.getDate()}-${lastDay.getMonth() + 1}-${lastDay.getFullYear()}`
                result.push({value: {from: firstDayString, to: lastDayString}, label: monthYearString})
            }
        }

        return result
    }

    function handleFetchAcceptedBookingsAndParseThemForTimeline(isFromPremiumPage, value) {
        if (isFromPremiumPage && !timelineIsOpened) {
            return
        }

        let firstDay
        let lastDay

        if (value) {
            firstDay = value.value.from
            lastDay = value.value.to
            setMonthYearStringSelectedForTimeline(value.label)
            setRangeSelectedMoment({
                from: toMomentDate(firstDay),
                to: toMomentDate(lastDay)
            })
        } else {
            firstDay = fromMomentDate(rangeSelectedMoment.from)
            lastDay = fromMomentDate(rangeSelectedMoment.to)
        }

        setLoadingTimelineBookings(true)
        const rentBookingsByDateView = httpsCallable(functions, 'rentBookingsByDateView');
        rentBookingsByDateView({rentId: rentUser.rentId, dateFilter: {from: firstDay, to: lastDay}})
            .then((response) => {
                const bookings = response.data.bookings
                setParsedItems([])
                let parsedItems = []
                bookings.forEach((booking, bookingIndex) => {
                    if (booking.status === orderStatus.ACCEPTED) {
                        booking.items.forEach((item, itemIndex) => {
                            const orderDatesFrom = booking.orderDates.from
                            const orderDatesTo = booking.orderDates.to
                            let itemTitle
                            let startTime
                            let toTime
                            let startTimeHours
                            let startTimeMinutes
                            let toTimeHours
                            let toTimeMinutes
                            if (booking.isSingleDayRent) {
                                const singleDaySlot = booking.orderDates.singleDaySlot
                                startTimeHours = parseInt(singleDaySlot.from)
                                startTimeMinutes = singleDaySlot.from.split(".")[1]
                                toTimeHours = parseInt(singleDaySlot.to)
                                toTimeMinutes = singleDaySlot.to.split(".")[1]

                                itemTitle = 'Dalle ' + startTimeHours + ':' + startTimeMinutes + ' alle ' + toTimeHours + ':' + toTimeMinutes

                                startTime = toMomentDate(orderDatesFrom).add(startTimeHours, 'hours').add(startTimeMinutes, 'minutes')
                                toTime = toMomentDate(orderDatesFrom).add(toTimeHours, 'hours').add(toTimeMinutes, 'minutes')
                            } else {
                                itemTitle = 'Dal ' + orderDatesFrom + ' al ' + orderDatesTo

                                startTime = toMomentDate(orderDatesFrom)
                                toTime = toMomentDate(orderDatesTo).add(1, 'day')
                            }
                            parsedItems.push({
                                id: bookingIndex + '-' + itemIndex,
                                group: item.item.id,
                                title: itemTitle,
                                start_time: startTime,
                                end_time: toTime,
                                itemProps: {
                                    onDoubleClick: () => handleItemDoubleClickOnBookingTimeline(booking),
                                    style: {
                                        borderRadius: GLOBAL_BORDER_RADIUS,
                                        zIndex: 0,
                                        background: booking.onCalendar?.backgroundColor
                                    }
                                }
                            })
                        })
                    }
                })
                setLoadingTimelineBookings(false)
                setParsedItems(parsedItems)
            }).catch(() => setLoadingTimelineBookings(false))
    }

    function handleItemDoubleClickOnBookingTimeline(booking) {
        setBookingIdDetailToOpen(booking.bookingId)
        setOpenBookingDetail(true)
    }

    function handleActivateChat(chatToAdd) {
        if (!activeChats.filter((chat) => chat.chatId === chatToAdd.chatId).length) {
            setActiveChats([...activeChats, ...[chatToAdd]])
        }

        if (chatToAdd.newMessagesAmount > 0) {
            const updateRentAppChat = httpsCallable(functions, 'updateRentAppChat');
            updateRentAppChat({rentId: rentUser.rentId, chatId: chatToAdd.chatId, newMessagesAmount: 0})
        }

        handleToggleChat(chatToAdd)
    }

    function handleDeleteChat(chat) {
        handleToggleChat(chat)
        const index = activeChats.indexOf(chat);
        if (index > -1) {
            activeChats.splice(index, 1);
            setActiveChats([...activeChats])
        }
    }

    function handleToggleChat(chat) {
        if (chat.chatId !== openChat.chatId) {
            setOpenChat(chat)
        } else {
            setOpenChat(openChatInitialValue)
        }
    }

    function handleSelectChat(chatId) {
        const chatsFiltered = chats.filter(chat => chat.chatId === chatId)
        const chatToAdd = chatsFiltered[0]
        if (!activeChats.filter((chat) => chat.chatId === chatToAdd.chatId).length) {
            setActiveChats([...activeChats, ...chatsFiltered])
        }
        if (chatToAdd) {
            setOpenChat(chatToAdd)
        } else {
            alert('Nessuna chat attiva per questo ordine.')
        }
    }

    return (
        <div style={dashboardPageStyle.dashboardPageContainer}>
            <HeaderDashboard pointer={false}/>
            <div style={dashboardPageStyle.dashboardMainDiv}>
                <div style={pageStyle.title}>
                    <MyBoldText fontSize={32}
                                text={'Ciao, ' + rentUser.name}/>
                    <div style={pageStyle.appInfos}>
                        <MyNormalText text={process.env.REACT_APP_MODE + ' mode'}/>
                        <MyNormalText text={'Version ' + process.env.REACT_APP_VERSION}/>
                    </div>
                </div>
                <BookingDetail rentUser={rentUser}
                               bookingId={bookingIdDetailToOpen}
                               isOpen={openBookingDetail}
                               setIsOpen={setOpenBookingDetail}
                               handleSelectChat={handleSelectChat}/>
                <div style={pageStyle.containerSections}>
                    <MyTimeline items={itemsForMyTimeline.slice()}
                                monthSelectedMoment={rangeSelectedMoment}
                                maxWidth={pageWidth}
                                timelineItems={parsedItems}
                                monthYearStrings={getMonthYearStrings()}
                                monthYearStringSelected={monthYearStringSelectedForTimeline}
                                handleFetchAcceptedBookingsAndParseThemForTimeline={handleFetchAcceptedBookingsAndParseThemForTimeline}
                                loading={loadingTimelineBookings}/>
                </div>
                <div style={{display: 'flex'}}>
                    <div style={pageStyle.containerButtons}>
                        <div style={pageStyle.marginBottom10}>
                            <MainButton text={'Ordini in arrivo'}
                                        backgroundColor={sectionSelected === dashboardSection.NEW_BOOKINGS ? SECONDARY_COLOR : PRIMARY_COLOR}
                                        action={() => changeSection(dashboardSection.NEW_BOOKINGS)}/>
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <MainButton text={'Premium'}
                                        backgroundColor={sectionSelected === dashboardSection.PREMIUM ? SECONDARY_COLOR : PREMIUM_COLOR}
                                        disabled={!isAccountPremium}
                                        action={() => changeSection(dashboardSection.PREMIUM)}/>
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <MainButton text={'Storico'}
                                        backgroundColor={sectionSelected === dashboardSection.HISTORY ? SECONDARY_COLOR : PRIMARY_COLOR}
                                        action={() => changeSection(dashboardSection.HISTORY)}/>
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <MainButton text={'Cerca ordine'}
                                        backgroundColor={sectionSelected === dashboardSection.SEARCH ? SECONDARY_COLOR : PRIMARY_COLOR}
                                        action={() => changeSection(dashboardSection.SEARCH)}/>
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <MainButton text={'I miei articoli'}
                                        backgroundColor={sectionSelected === dashboardSection.MY_ITEMS ? SECONDARY_COLOR : PRIMARY_COLOR}
                                        action={() => changeSection(dashboardSection.MY_ITEMS)}/>
                        </div>
                        <div style={pageStyle.marginBottom10}>
                            <MainButton text={'Il mio profilo'}
                                        backgroundColor={sectionSelected === dashboardSection.PROFILE ? SECONDARY_COLOR : PRIMARY_COLOR}
                                        action={() => changeSection(dashboardSection.PROFILE)}/>
                        </div>
                    </div>
                    <div style={{...pageStyle.containerSections, width: '100%', alignSelf: 'flex-start'}}>
                        {sectionSelected === dashboardSection.NEW_BOOKINGS ?
                            <div style={pageStyle.section}>
                                <NewBookings rentUser={rentUser}
                                             newBookings={newBookings}/>
                            </div> : <></>}
                        {sectionSelected === dashboardSection.PREMIUM ?
                            <div style={pageStyle.section}>
                                <Premium rentUser={rentUser}
                                         handleSelectChat={handleSelectChat}
                                         reloadTimelineBookings={handleFetchAcceptedBookingsAndParseThemForTimeline}
                                         items={itemsForPremiumSection}/>
                            </div> : <></>}
                        {sectionSelected === dashboardSection.HISTORY ?
                            <div style={pageStyle.section}>
                                <BookingHistory user={rentUser}
                                                bookings={bookingsHistory}
                                                setBookings={setBookingsHistory}
                                                monthYearStrings={getMonthYearStrings()}
                                                monthYearStringSelected={monthYearStringSelectedForHistory}
                                                setMonthYearStringSelected={setMonthYearStringSelectedForHistory}
                                                handleSelectChat={handleSelectChat}/>
                            </div> : <></>}
                        {sectionSelected === dashboardSection.SEARCH ?
                            <div style={pageStyle.section}>
                                <SearchBookings user={rentUser}
                                                historyBookings={bookingsSearched}
                                                setHistoryBookings={setBookingsSearched}
                                                searchValue={searchValue}
                                                setSearchValue={setSearchValue}
                                                loading={loadingSearch}
                                                setLoading={setLoadingSearch}
                                                handleSelectChat={handleSelectChat}/>
                            </div> : <></>}
                        {sectionSelected === dashboardSection.MY_ITEMS ?
                            <div style={pageStyle.section}>
                                <MyItems rentUser={rentUser}
                                         items={itemsForMyItems.slice()}
                                         setItems={setItemsForMyItems}
                                         itemsArchived={itemsWithArchivedForMyItems}
                                         refreshItems={refreshItems}
                                         setRefreshItems={setRefreshItems}
                                         loadingItems={loadingItems}/>
                            </div> : <></>}
                        {sectionSelected === dashboardSection.PROFILE ?
                            <div style={pageStyle.section}>
                                <Profile user={rentUser}
                                         setUser={setRentUser}
                                         handleOnLogout={handleOnLogout}/>
                            </div> : <></>}
                    </div>
                    <div style={pageStyle.containerChat}>
                        <ChatList chatRef={chatListRef}
                                  handleActivateChat={handleActivateChat}
                                  chats={chats}/>
                    </div>
                </div>
            </div>
            <div style={pageStyle.containerFooterChat}>
                <ChatFooter chatFooterRef={chatFooterRef}
                            rentUser={rentUser}
                            activeChats={activeChats}
                            openChat={openChat}
                            maxWidth={pageWidth - 40}
                            handleActivateChat={handleActivateChat}
                            handleToggleChat={handleToggleChat}
                            handleDeleteChat={handleDeleteChat}/>
            </div>
        </div>
    );
}

const pageStyle = {
    title: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        margin: 5
    },
    containerButtons: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        margin: 5,
        padding: 5,
        width: 300,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    },
    containerChat: {
        margin: 5,
        padding: 5,
        alignSelf: 'flex-start',
        width: 300,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    },
    containerSections: {
        margin: 5,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    },
    containerFooterChat: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    marginBottom10: {
        margin: 5
    },
    appInfos: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default Dashboard;
