import React from "react";
import MyNormalText from "../commons/myNormalText";

function Footer({language}) {

    const pageStyle = {
        footer: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            alignItems: 'center',
            backgroundColor: 'white'
        },
        footerSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flexStart',
            flexWrap: 'wrap'
        },
        footerSubSection: {
            display: 'flex',
            flexDirection: 'column',
            margin: 20,
            fontSize: 18
        }
    }

    return (
        <footer style={pageStyle.footer}>
            <div style={pageStyle.footerSection}>
                <div style={pageStyle.footerSubSection}>
                    <MyNormalText text={'Privacy Policy'}
                                  link={'/app/locem-website/privacy-policy'}/>
                </div>
                <div style={pageStyle.footerSubSection}>
                    <MyNormalText text={'Cookies Policy'}
                                  link={'/app/locem-website/cookies-policy'}/>
                </div>
                <div style={pageStyle.footerSubSection}>
                    <MyNormalText textWithTranslation={'footer.contacts'}
                                  language={language}
                                  link={'/contacts'}/>
                </div>
                <div style={pageStyle.footerSubSection}>
                    <MyNormalText textWithTranslation={'footer.legal.notes'}
                                  language={language}
                                  link={'/legal-notes'}/>
                </div>
            </div>
            <div style={pageStyle.footerSection}>
                <div style={pageStyle.footerSubSection}>
                    <a href='https://www.facebook.com/profile.php?id=61556466463198'>
                        <i className="fa fa-facebook"/>
                    </a>
                </div>
                <div style={pageStyle.footerSubSection}>
                    <a href='https://www.instagram.com/locemsolutions/'>
                        <i className="fa fa-instagram"/>
                    </a>
                </div>
            </div>
            <div style={{...pageStyle.footerSubSection, ...{fontSize: 14}}}>
                <span>P.IVA 04967430234</span>
                <span>Copyright &copy; 2024 LoceM Solutions</span>
            </div>
        </footer>
    )
}

export default Footer
