import React, {useState} from "react";
import ItemBox from "../item/itemBox";
import Loader from "../commons/loader";
import {GLOBAL_BORDER_RADIUS, PRIMARY_COLOR, SECONDARY_COLOR} from "../../global/costants";
import MainButton from "../commons/mainButton";
import ButtonsToOrderItems from "../premium/buttonsToOrderItems";

const VETRINA = 'VETRINA'
const ARCHIVE = 'ARCHIVE'

export const SIZES = {"KIDS": 0, "XS": 1, "S": 2, "M": 3, "L": 4, "XL": 5}

function MyItems({
                     rentUser,
                     items,
                     setItems,
                     itemsArchived,
                     loadingItems,
                     refreshItems,
                     setRefreshItems
                 }) {

    const [sectionSelected, setSectionSelected] = useState(VETRINA)

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.buttonsContainer}>
                <div style={pageStyle.button}>
                    <MainButton text={'In Vetrina (' + items.length + ')'}
                                action={() => setSectionSelected(VETRINA)}
                                backgroundColor={sectionSelected === VETRINA ? SECONDARY_COLOR : PRIMARY_COLOR}/>
                </div>
                <div style={pageStyle.button}>
                    <MainButton text={'Archiviati (' + itemsArchived.length + ')'}
                                action={() => setSectionSelected(ARCHIVE)}
                                backgroundColor={sectionSelected === ARCHIVE ? SECONDARY_COLOR : PRIMARY_COLOR}/>
                </div>
            </div>
            <ButtonsToOrderItems items={items}
                                 setItems={setItems}
                                 buttonsColor={PRIMARY_COLOR}/>
            {sectionSelected === VETRINA ?
                <div style={pageStyle.itemList}>
                    {loadingItems ?
                        <Loader color={SECONDARY_COLOR}/> :
                        <></>}
                    {items.map((item) => {
                        return (
                            <div key={item.id}
                                 style={pageStyle.itemContainer}>
                                <ItemBox rentUser={rentUser}
                                         refreshItems={refreshItems}
                                         setRefreshItems={setRefreshItems}
                                         openOperations={true}
                                         item={item}/>
                            </div>
                        )
                    })}
                </div> : <></>}
            {sectionSelected === ARCHIVE ?
                <div style={pageStyle.itemList}>
                    {loadingItems ?
                        <Loader color={SECONDARY_COLOR}/> :
                        <></>}
                    {itemsArchived.map((item) => {
                        return (
                            <div key={item.id}
                                 style={pageStyle.itemContainer}>
                                <ItemBox rentUser={rentUser}
                                         refreshItems={refreshItems}
                                         setRefreshItems={setRefreshItems}
                                         openOperations={true}
                                         item={item}/>
                            </div>
                        )
                    })}
                </div> : <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    buttonsContainer: {
        display: 'flex',
        marginBottom: 10
    },
    itemList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10
    },
    itemContainer: {
        marginBottom: 20,
        marginRight: 10,
        backgroundColor: PRIMARY_COLOR,
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    button: {
        marginRight: 10
    }
}

export default MyItems
