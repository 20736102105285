import {useNavigate} from 'react-router-dom';
import React, {useState} from "react";
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth, functions} from "../../firebase";
import {httpsCallable} from "firebase/functions";
import {globalPageStyle} from "../../global/globalPageStyle";
import MainButton from "../commons/mainButton";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import MyMenu from "../menu/myMenu";
import Footer from "../home/footer";
import {SECONDARY_COLOR} from "../../global/costants";
import HeaderDashboard from "../header/headerDashboard";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";
import Languages from "../home/languages";

function Login({handleOnLogin, languageState}) {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                const getRentAppUser = httpsCallable(functions, 'getRentAppUser');
                getRentAppUser({rentId: user.uid}).then(async (result) => {
                    const rentUser = result.data.rentUser;
                    if (rentUser) {
                        handleOnLogin(rentUser).then(() => navigate('/dashboard'))
                    }
                    setLoading(false)
                }).catch(() => setLoading(false));
            })
            .catch(() => setLoading(false))
    }

    return (
        <div style={{display: 'flex', height: '100vh', width: '100%'}}>
            <Languages languageState={languageState}/>
            <MyMenu language={languageState.language}/>
            <div style={globalPageStyle.pageBackground}>
                <HeaderDashboard pointer={true}
                                 navigateTo={'/'}/>
                <main style={globalPageStyle.main}>
                    <div style={globalPageStyle.mainSection}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <MyBoldText textWithTranslation={'login.page.are.you.our.partner'}
                                        language={languageState.language}/>
                            <MyNormalText textWithTranslation={'login.page.are.you.our.partner.sentence'}
                                          language={languageState.language}/>
                            <form>
                                <div style={pageStyle.form}>
                                    <input id="email-address"
                                           style={globalElementInputStyle.customInput}
                                           name="email"
                                           type="email"
                                           required
                                           placeholder="Email"
                                           onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div style={pageStyle.form}>
                                    <input id="password"
                                           style={globalElementInputStyle.customInput}
                                           name="password"
                                           type="password"
                                           required
                                           placeholder="Password"
                                           onChange={(e) => setPassword(e.target.value)}/>
                                </div>
                                <div style={pageStyle.form}>
                                    <MainButton text={'Login'}
                                                action={onLogin}
                                                backgroundColor={SECONDARY_COLOR}
                                                loading={loading}/>
                                </div>
                            </form>
                            <MyNormalText textWithTranslation={'login.page.dont.work.with.us.yet'}
                                          language={languageState.language}/>
                            <MyBoldText text={'hello@locemsolutions.com'}/>
                            <MyNormalText textWithTranslation={'login.page.dont.work.with.us.yet.start.the.adventure'}
                                          language={languageState.language}/>
                        </div>
                    </div>
                </main>
                <Footer language={languageState.language}/>
            </div>
        </div>
    )
}

const pageStyle = {
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 20
    }
}

export default Login;
